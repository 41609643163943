.cursor-pointer{
    cursor: pointer !important;
}

.logomain {
    p {
        color: #9E9E9E;
        font-size: 1.02rem;
    }

    .log-fnt {
        color: #2F5496;
        font-weight: 600;
        font-size: 2.8rem;

        .log-360 {
            color: #007ACC;
        }
    }

    .tm {
        color: #007ACC;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
    }
}

.redborder 
{
	select {
		border: 1px solid #D70015!important;
	}
	label {
		color:  #D70015!important;
	}
	&:focus {
		border: 1px solid #D70015;
		& + .label {
			
			color: #D70015!important;
			
		}
	} 
}
.redborder-btm
{
    border-bottom: 1px solid #D70015!important;
}
.login-pg{
    height: 100vh;
}
.header-section{
    z-index: 100 !important;
}
.frm-maincontent .form-main{
    z-index: 99 !important; 
}
.log-txt{
    z-index: 999;
}
 
#calenderIcon {
    position: absolute;
    right: 12px;
    top: 13px;
    font-size: 16px;
    color: #c3cbe4;
    cursor: pointer;
    }
.date-picker-styles, .react-datepicker__input-container {
    height: 45px !important;
    width: 100% !important;
    // border: 1px solid  #9E9E9E !important;
    border-radius: 5px;
}
.progress{
    height: 5px;
}
.p-15{
    height: 45px !important;
}
.react-datepicker-popper{
    z-index: 9999;
}
// .react-datepicker__input-container input { 
//     padding: 0.625rem 1.25rem !important; 
// }
.dateicon{
    font-size: 20px;
    // position: absolute;
    // right: 14%;
    // top: 13px; 
    position: absolute;
    top: 13px;
    right: 10px;
    /* pointer-events: none; */
    cursor: pointer;
    color: #aaa;
    z-index: 2;
}
.inputContainer{
    height: 45px !important;
    .label{
        font-weight: 400 !important;
        // font-size: 14px !important; 
    }
} 
.label {
    position: absolute;
    top: 0.7rem;
    left: 0.4rem;
    padding: 0 4px;
    background-color: $background-color_1;
    color: #9E9E9E;
    font-size: 0.8rem;
    transition: 0.2s;
    z-index: 0;
    // font-weight: 400;
}
.top68 {
    top: 68% !important;
}
.top60 {
    top: 60% !important;
}

.right5{
    right: 5% !important;
}

.ct-data
    {
        text-align: justify;
        input{ 
            color: #000 !important;
            font-weight: 500px !important;
            &:focus
            {
            outline: none !important;
            box-shadow: none !important;
            border:none !important;
            border-bottom: 1.2px solid #a3a3a3 !important;
            font-size: 18px !important;
            color: #000 !important;
            font-weight: 500px !important;
            }
        }
    }
    .certify-data{
        text-align: center !important;
    }

    .sigCanvas{
        border: 2px dotted #CCCCCC;;
        border-radius:15px;
        height: 200px;
        width:480px;
    }
    .esignImg{
        margin-top: 43px;
        margin-left: 50px;
        margin-bottom:10px;
    }
    select{
        background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") !important;
        background-repeat:no-repeat !important;
        background-position:right .75rem center !important;
        background-size:16px 12px !important;
        border:1px solid var(--bs-input-border-color) !important;
        border-radius:.25rem important;
    }
    .react-datepicker__month-select, .react-datepicker__year-select{
        background-image:none !important;
    }
    .react-datepicker__input-container {
        ::placeholder {   
            color: #9E9E9E;
            font-size: 1rem;
            font-weight: 400 !important;
            left: 0.938rem;
          }
    }
  .w5{
    width: 5%;
  }
 
  .activeNav {
    display: inline-grid;
    border: 0px;
    color: green !important;
    background: transparent;
    cursor: pointer;
    .rnd-data {
        color: white !important;
        border: 1px solid green;
        background-color: green;
    }
    span{
        color: green !important;
        cursor: pointer;
    }
}
.servicescontainer {
    p {
        font-weight: 400;
        font-size: 16px;
    }
}
.validation-message{
    width:400px !important;
}
.cst-checkbox
{
  .form-check-ct
  { 
      .form-check-input
      { 
          &:focus {
              font-size: inherit !important;
          }
      } 
  }
  
}
.bordersolid{
    border: solid 1px;
}

// for ips modal

.model-commen {
    justify-content: center;
    .common-modal-content {
      width: 100%;
      border: none;
      .common-model-body {
        text-align: center;
        .popheading {
          margin: 0%;
          font-size: 14px;
        }
        .poppara {
          margin: 0%;
          font-size: 12px;
          margin: 9px 0px 14px 0;
        }
        .popup-icon {
          margin-bottom: 10px;
        }
      }
  
      .pop-btn1 {
        background-color: #007ACC;
        color: #ffffff;
        border: none;
        box-shadow: 0px 2px 4px -1px #007ACC;
        border-radius: 5px;
        font-size: 15px;
        padding: 8px 30px;
      }
      .pop-btn2 {
        font-size: 15px;
        padding: 8px 20px;
        border: 1px solid #007ACC;
        // filter: drop-shadow(0px 2px 4px $shadow-color);
        border-radius: 5px;
        color: #007ACC;
        margin-left: 10px;
        background-color: #ffffff;
      }
    }
    .modalHeight{
        max-height: 600px;
        overflow-y: auto;
    }
  }

  // styles for IPS

  
#ipsPdf
{
    font-family: 'Manrope', sans-serif;
    font-size: 12px !important;
    font-weight: 500;
}
.txt-left
{
    text-align: left;
    padding-bottom: 20px;
    font-size: 12px;
    padding-top: 10px;
}
.head, .ft
{
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    text-align: left;
}
.main-header
{
    display:flex;
    border-bottom: 0.01rem solid #aaa;
    padding-top: 25px;
    padding-bottom: 12px;
    margin: 0px;
}
.ips-header-tittle h5{
    font-weight: 700;
    font-size: large;
}
.main-header img{
    float: right;
}
.ft-right
{
    text-align: right;
}
.main-footer
{
    display: flex;
    border-top: 0.01rem solid #aaa;
    padding-top: 18px;
    padding-bottom: 0px;
    margin: 0px;

}
.dt-fnt
{
    font-size: 12px;
}
.ips-content
{
    padding: 10px 0;
}
.bg-vio
{
    background: #e6f2fa;
    padding: 10px;
    // font-weight: 700;
     border-bottom: 1px solid #373b3e;
    margin-bottom: 0px;
    font-size: 24px;
    text-align: center;
    color:#000;
}
.table-grey {
    --bs-table-color: #fff;
    --bs-table-bg: #D5D5DA;
    --bs-table-border-color: #373b3e;
    --bs-table-striped-bg: #2c3034;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #373b3e;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #323539;
    --bs-table-hover-color: #fff;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color);
}
.col-dark th{
    color: #373b3e;
    font-weight: 500;
    /* font-size: 14px; */

}

.fnt-s-12 tr{
    border-bottom-width: 1px solid ;
}
/* .fnt-s-12 tr td{
    padding: 10px;
} */
tbody, td, tfoot, th, thead, tr {
    /* border-color: inherit; */
    /* border-style: solid; */
    border-width: 0;
}
.fnt-s-12 tr td
{
    border-style: unset!important;
    padding: 10px;
}
.brd-cust
{
    border-bottom: 0.01rem solid rgb(50, 49, 49);
    font-size: 12px;
    text-align: start;
}
td { white-space:pre-line }

.total-dt
{
    font-weight: 700;
}
.col-dark
{
    font-size: 14px;
    vertical-align: middle;
    text-align: start;
}
.canvasjs-chart-credit
{
    display: none;
}
.int-main
{
    padding-top: 30px;
}
.logo-width
{
    width: 250px;
    margin-left: -79%;
}
.min-head
{
    font-size: 4rem;
    word-wrap: break-word;
    color: #6848F2;
    padding-top: 60px;
    line-height: 5rem;
    letter-spacing: 2px;
    padding-bottom: 10px;
    text-align: left;
    margin-left: 1rem;
    font-weight: 500;
}

.main-span
{
    color: #929698;
    font-size: 1.5rem;
    line-height: 3rem;
    text-align: left;
    margin-left: 3%;
}
.placeholder-img img
{
    width: 600px;
    position: absolute;
    right: 0;
    top: 3%;
}

.main-span span{
    font-weight: 700;
    font-size: 1.5rem;
}
#rot_data
{
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    transform: rotate(-90deg);
    /* width: 100px; */
    position: absolute;
    top: 3%;
    right: -5%;
}
.pd-45
{
    padding: 0px 45px ;
}
.ipsdata
{
    font-size: 14px;
    text-align: start;
    padding-bottom: 15px;
}

.container-div {
    width: 100%;
    height: 20px;
    text-align: center;
    background-color: #deedf4;
    border-radius: 10px;
    text-align: center;
    vertical-align: middle;
    position: relative;
    margin-top: 50px;
  }
  
  .percent-bar-low {
    width: 10%;
    height: 20px;
    background-color: #766F92;
    border-radius: 10px;
    position: absolute;
    z-index: 5;
  }
  .percent-bar-low p {
    font-size: 10px;
    color:#fff;
    vertical-align: middle;
    padding-top: 3px;
  }
  .percent-bar-medium {
    width: 30%;
    height: 20px;
    text-align: center;
    background-color: #8A6EF8;
    border-radius: 10px;
    position: absolute;
    z-index: 4;
  }
  .percent-bar-medium p {
    font-size: 10px;
    color:#FFFFFF;
    vertical-align: middle;
    padding-top: 3px;
    margin-left: 25px;
    text-align: center;
  }
  .percent-bar-ideal {
    width: 80%;
    height: 20px;
    text-align: center;
    background-color: #A79BD7;
    border-radius: 10px;
    position: absolute;
    z-index: 3;
  }
  .percent-bar-ideal p {
    font-size: 10px;
    color: #FFFFFF;
    vertical-align: middle;
    padding-top: 3px;
    text-align: center;
    margin-left: 38%;
    font-weight: 700;
  }
  .percent-bar-high {
    width: 100%;
    height: 20px;
    text-align: center;
    background-color: #382C67;
    border-radius: 10px;
    position: absolute;
    z-index: 2;
  }
  .percent-bar-high p{
    margin-left: 78%;
    color:#fff;
  }
  .tooltip-cst {  
    position: relative;
    font-weight: bold;
    font-size: 9px;
    border: 1px solid rgba(0, 0, 0, 0.4549019608);
    padding: 7px 12px;
    background-color: #ffffff;
    width: 8vw;
    margin: 16px auto;
    text-align: center;
    left: 20%;
    border-radius: 10px;
    margin-bottom: 13px;
  }
  .tooltip-top::before {
    content: '';
    content: "";
    position: absolute;
    display: block;
    width: 0px;
    left: 50%;
    top: 4px;
    border: 4px solid rgba(37, 33, 33, 0);
    border-top: 0;
    border-bottom: 16px solid #000000;
    transform: translate(-50%, calc(-100% - 5px));

  }
  .tooltip-low::before {
    content: '';
    content: "";
    position: absolute;
    display: block;
    width: 0px;
    left: 15%;
    top: 4px;
    border: 4px solid rgba(37, 33, 33, 0);
    border-top: 0;
    border-bottom: 16px solid #000000;
    transform: translate(-50%, calc(-100% - 5px));

  }
  .mr-top-30
  {
    margin-top: 30px;
  }
  .lw{
    position: absolute;
    bottom: 32px;
    left: 8px;
    font-weight: 700;
  }
  .md{
    position: absolute;
    bottom: 32px;
    left: 50px;
    font-weight: 700;
  }
  .id{
    position: absolute;
    bottom: 32px;
    left: 65%;
    font-weight: 700;
  }
  .hi{
    position: absolute;
    bottom: 32px;
    left: 375px;
    font-weight: 700;
  }
.mapped
{
    position: absolute;
    text-align: center;
    left: 54%;
    background: #d5d5da;
    padding-left: 5px;
    padding-right: 5px;
    z-index: 9;
}
.data h6
{
    font-size: 14px;
    font-weight: 400;
    white-space: pre-line;
    display: grid;
    padding-block-end: 10px;
}
.data{
    text-align: start;
}
.data h6 span
{
    font-weight: 600;
    padding-bottom: 5px;
}
.br-top
{
    border-top: 1px solid #000;
}
.rectangle-pln {
    height: 30px;
    width: 335px;
    border-right: 1px solid rgb(0, 0, 0);
    border-top: 1px solid rgb(0, 0, 0);
    border-left: 1px solid rgb(0, 0, 0);
    left: 42%;
    position: absolute;
    margin-top: -35px;

  }
  .unrectangle-pln
  {
    height: 50px;
    width: 450px;
    border-right: 1px solid rgb(0, 0, 0);
    border-top: 1px solid rgb(0, 0, 0);
    border-left: 1px solid rgb(0, 0, 0);
    left: 46.5%;
    position: absolute;
    margin-top: -52px;
  }
  .rectangle-unpln {
    height: 30px;
    width: 336px;
    border-right: 1px solid rgb(0, 0, 0);
    border-top: 1px solid rgb(0, 0, 0);
    border-left: 1px solid rgb(0, 0, 0);
    left: 70.5%;
    position: absolute;
    margin-top: -35px;
    // top: 30%;
  }
  .rectangle-mp
  {
    height: 46px;
    width: 465px;
    border-right: 1px solid rgb(0, 0, 0);
    border-top: 1px solid rgb(0, 0, 0);
    border-left: 1px solid rgb(0, 0, 0);
    left: 46.5%;
    position: absolute;
    // top: 34%;
  }
  .mapped-planned
  {
    margin-top: -4px;
    position: absolute;
    left: 48%;
    background: #d5d5da;
    padding-left: 5px;
    padding-right: 5px;
    z-index: 9;
    
  }
  .mapped-unplanned
  {
    position: absolute;
    left: 77%;
    // top: 29%;
    background: #d5d5da;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: -4px;
    z-index: 9;
    
  }
  .bt-1
  {
    border-bottom: 1px solid #000!important;
  }
  .wt-sp-pre
  {
    white-space: pre
  }
.lf
{
    text-align: left;
}
.br-bt-0
{
    border-bottom: 0px;
}
.ft-14
{
    font-size: 14px;
}
.btm-rec
{
bottom: 36.2%!important;
}
.ft-13
{
    font-size: 13px;
}
.cur-red
{
    right:30% !important;
    bottom: 24% !important;
}
.cur-blue
{
    right:30% !important;
    bottom: 30% !important;
}
.mt-0
{
    margin-bottom: 0px!important;
}
.pt-top{
    padding-top: 5px;
}
  /* Content styles */
  .content-list{
    font-size:12px;
    font-weight: 400;
    text-align:justify;
    line-height: 22px;
    margin-top: 10px;
   
}
.list{
    padding: 5px !important;
    float: none !important;
}
.step-heading{
    font-weight: 700;
    font-size: 15px;
}
.para-text{
    margin-top: 10px;
}
.trow{
    border: hidden;
    font-size: 12px;
    margin-top: 10px;
}
/* .table{
    line-height: 5px;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 20px;
} */
.col-dark th{  
    /* padding: 12px; */
}
.fnt-s .brd-cust
{
    font-size: 13px;
}
.tb-hed
{
    text-align: left;
    color:#373b3e;
    font-weight: 500;
    font-size: 12px;
}
.ring-icon
{
    width: 150px;
    margin: 0 auto;
    margin-top:25px;
}
.content-list p{
    margin-bottom: 2rem;
}
.table > :not(:first-child) {
    border-top: 1px solid #000!important;
}
.mrt-btm-30
{
    margin-bottom: 45px;
    text-align: left;
    font-size: 12px;
}
.wit-20
{
    width: 24%!important;
}
.wit-35
{
    width: 35%;
}
.bt-left
{
    border-left: 1px solid;
}
.bt-right
{
    border-right: 1px solid;
}
.wit-80
{
    width: 80%;
}
.wit-10
{
    width: 10%;
}
.br-unset
{
    border-bottom: unset!important;
}
.text-right
{
    text-align: right!important;
    // padding-right: 5rem!important;

}
.percent-bar-high p {
    font-size: 10px;
    color: #FFFFFF;
    vertical-align: middle;
    padding-top: 3px;
    text-align: center;
    font-weight: 700;
}
.text-center
{
    text-align: center;
}

.close-btn
{
  font-size: 20px;
  float: right;
  margin-top: -26px;
  color:#929698;
  cursor: pointer;
}
.assetStyle{
    font-size: 12px;
    text-align: left;
    margin-bottom: 10px;
}
.goal-right{
    padding-right: 69px!important;
    text-align: right;
}
.cst-load-icon
{
    margin-left: auto;
    margin-right: 0;
}
.cst-head-ips{
    font-size: 16px;
    line-height: 22px;
    color: #000;
    margin-bottom: 15px;
    font-weight: 600;
    padding: 10px 0 0 15px;
    margin-left: 10px;
}
.progress-bar{
    height: 64px;
    margin-left: 20px;
    width: 59px;
    margin-top: 3px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.custom-progress-value{
    position: absolute;
}
.rc-progress-line {
height: 8px;
width: 100%;
margin-top: -10px;
} 
.count{
    display: flex;
    justify-content: end;
}
.rd-mandatory{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    text-decoration-line: none !important;
    color: #cc001c !important;
    white-space: initial;
}
.rd-doc{
    color: #D70015 !important;
    font-weight: 400 !important;
}
.esign {
    position: absolute;
    left: 1.5%;
    padding: 10px;
    color: #A3A3A3;
    
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
}
.alert {
    position: absolute;
    left: 1.5%;
    padding: 10px;
    color: #D70015;
    
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
}
.hovericon {
    border: none;
    background-color: transparent;
    display: inline-block;
    &.addmem {
      color: #007ACC;
      font-size: 14px;
      cursor: pointer;
    }
  }
