
$background-color : #252628;
$white : #fff;
@font-face {
    font-family: "tahoma", sans-serif;
    src: url("../assets/fonts/Tahoma.woff") format("woff"),
    url('../assets/fonts/tahoma.ttf')  format('truetype'), /* Safari, Android, iOS */

    }
	
body
{
    font-family: "tahoma", sans-serif !important;
	overflow: hidden;
}

.logo-main {
	position: absolute;
	left: 5px;
	top: -9px;
    color: $white;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    letter-spacing: 0.075em;
    vertical-align: middle;
    margin-left: 5px;
	.logo-txt
	{
		vertical-align: middle;
		font-size: 22px;
	}
    img {
        vertical-align: sub;
    }
	&:hover {
		color: #fff;
	}
}
.ptn-btn {
	display: flex;
	margin: 12px;
	padding: 3px;
	margin-top: 9px;
	color: #2d2d2d;
	
	span {
		margin-top: 4px;
		margin-left: 3px;
		padding-right: 5px;
		&:hover {
			color: #007ACC;
		}
	}
	&:hover {
		background: #ECECEC;
		padding: 3px;
		color: #007ACC;
	}
}
.pat-head {
	font-weight: 500;
	color: #2d2d2d;
	
	font-size: 2.5rem;
}
.crt-account {
	&:hover {
		color: #007ACC;
		background-color: #ECECEC;
	}
	svg {
		vertical-align: middle!important;
	}
}
.icon-action {
	padding: 6px;
	display: flex;
	padding-left: 0px;
	font-size: 0.9rem;
}
.on-action {
	a {
		color: #9E9E9E;
		width: 1.5rem;
		svg {
			color: #9E9E9E;
			width: 1.5rem;
			height: 1.2rem;
		}
	}
}
#patients-list_info {
	display: block;
	text-align: start;
	font-size: 12px;
	
}
div {
	&.dataTables_wrapper {
		div {
			&.dataTables_paginate {
				margin: 0px auto!important;
				text-align: center;
				width: 200px;
			}
		}
	}
}
.pagination {
	border-radius: 50%;
	padding: 10px;
}
.page-item {
	&:last-child {
		.page-link {
			border-radius: 50%;
			padding: 7px 15px;
			outline: none;
			box-shadow: none;
		}
	}
	&:first-child {
		.page-link {
			border-radius: 50%;
			padding: 7px 15px;
			outline: none;
			box-shadow: none;
		}
	}
}
.page-link {
	border-radius: 50%;
	margin-right: 10px;
	padding: 7px 14px;
}
.table-header {
	background-color: #E6F2FA;
	text-align: center;
	color: #9e9e9e;
	font-size: 14px;
	
}
table.dataTable td, table.dataTable th {
	vertical-align: baseline;
	padding: 0px;
	padding-left: 7px;
}
table.dataTable thead th, table.dataTable thead td, table.dataTable tfoot th, table.dataTable tfoot td {
	padding: 7px;
}
table.dataTable tbody th,table.dataTable tbody td {
	border-bottom: 0.01rem solid #BDDBF0;
}
table {
	&.dataTable {
		& > tbody {
			& > tr {
				background-color: #fbfbfb;
			}
		}
	}
}
.active>.page-link, .page-link.active {
	z-index: 3;
	background-color: #007ACC;
	border-color: #007ACC;
	outline: none;
	box-shadow: none;
}
.pull-left {
	position: absolute;
}
.choose-data {
	display: flex;
	position: absolute;
	left: 20%;
	margin-top: 3px;
	z-index: 99;
}
.radio-select {
	margin-left: 10px;
	label {
		color: #2d2d2d;
	}
	input {
		vertical-align: middle;
		margin-right: 5px;
	}
}
.pcoded-navbar {
	.pcoded-inner-navbar {
		li {
			& > a {
				& > .pcoded-micon {
					& + .pcoded-mtext {
						position: relative;
						top: 5px;
						vertical-align: top;
					}
				}
			}
		}
	}
}
.header-item {
	svg {
		color: #fff;
		&:hover {
			color: #007ACC;
		}
	}
}
.patient-li {
	padding-left: 0px;
	a {
		vertical-align: middle;
	}
}
.card-body {
	padding: 0px;
}
.view-pager {
	color: #2d2d2d;
}
#patients-list_length {
	color: #2d2d2d;
}
.ft-size {
	font-size: 16px;
}
.breadcrumb-item {
	& > a {
		color: #007ACC;
		font-weight: 500;
		
	}
}
.breadcrumb {
	--bs-breadcrumb-padding-x: 0rem;
	--bs-breadcrumb-padding-y: 0rem;
}
.tb-nm {
	color: #9E9E9E;
	font-size: 12px;
	
}
.tb-main {
	color: #292929;
	font-family: 'tahoma', sans-serif;
	font-size: 13px;
	img {
		padding-right: 5px;
		vertical-align: sub;
	}
}
.br-bottom {
	border-bottom: 1px solid #9E9E9E68;
	bottom: 0px;
}
.pad-bot-0 {
	padding-bottom: 0px!important;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
	color: #007ACC;
	background-color: #fff;
	border-bottom: 3px solid #007ACC;
	border-radius: 0px;
	padding: 0px;
	padding-bottom: 8px;
	padding-top: 8px;
	padding-left: 0px;
	padding-right: 0px;
}
.nav-link {
	color: #9E9E9E;
	padding-left: 0px;
    padding-bottom: 0px;
    
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    padding-top: 10px;
    padding-right: 4px;
	
}
.nav-link.scrollto.active
	{
	color: #007ACC;
	background-color: #fff;
	border-bottom: 3px solid #007ACC;
	width: fit-content;
	}
.recent-nurse-note, .diagnosis-data,.allergy-data,.documents-data {
	background: #FFFFFF;
	box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.16);
	border-radius: 5px;
	padding: 20px;
}
.recent-nurse-note {
	padding: 0px!important;
	h5 {
		
		font-size: 13px;
		line-height: 20px;
		color: #2D2D2D;
	}
}
.rec-nur-head {
	
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #9E9E9E;
	background: #F7F8F9;
}
.avatar_rounded {
	border-radius: 50%;
	width: 180px;
}
.loader {
	width: 18px;
	height: 18px;
	margin-right: 5px;
	-webkit-animation: spin 2s linear infinite;
	animation: spin 2s linear infinite;
	background: url('https://api.iconify.design/fa/spinner.svg?color=white&rotate=90deg') no-repeat center center / contain;
}
.pt-summary-right {
	background: #FFFFFF;
	box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.16);
	border-radius: 5px;
	padding: 10px;
	height: 100%;
}
.navtab-pt {
	background: #FFFFFF;
	// box-shadow: 0px 7px 10px -8px rgba(0, 0, 0, 0.25);
	// border-radius: 5px 5px 0px 0px;
}
.tb-content-pt {
	height: 280px;
	overflow-y: scroll;
}
* {
	scrollbar-width: thin;
	scrollbar-color: transparent;
}
*::-webkit-scrollbar {
	width: 0px;
}
*::-webkit-scrollbar-track {
	background: transparent;
}
*::-webkit-scrollbar-thumb {
	background-color: transparent;
	border-radius: 20px;
	border: 0px solid orange;
}
.fnt-12 {
	font-size: 12px;
	margin-bottom: 0.5rem;
}
.upload-profile {
	position: relative;
	&:hover {
		.overlay {
			opacity: 0.7;
			cursor: pointer;
		}
	}
}
.image {
	display: block;
	width: 180px;
	border-radius: 50%;
}
.overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 160px;
	border-radius: 50%;
	opacity: 0;
	transition: .5s ease;
	background-color: #292929;
}
.text {
	color: white;
	font-size: 12px;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	text-align: center;
}
.wrapper {
	display: none;
}
.vitals-data {
	background: #F7F8F9;
	border-radius: 5px;
	padding: 10px;
	padding-bottom: 0px;
}
.mrg-bot-0 {
	margin-bottom: 5px;
}
.ht-30 {
	height: 70px;
}
.pt-sum-content {
	&:after {
		border-bottom: 1px solid #ccc;
	}
}
// .br-btm {
// 	&:before {
// 		position: absolute;
// 		left: -10px;
// 		width: 80%;
// 		height: 1px;
// 		border-top: 1px solid #0000001a;
// 		content: "";
// 	}
// }
.summary-header {
	display: flex;
	padding: 20px 12px 6px 12px;
}
.upload-btn-wrapper {
	position: absolute;
	overflow: hidden;
	display: inline-block;
	right: 35px;
	cursor: pointer;
	input[type=file] {
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;
	}
}
.upload-btn {
	color: gray;
	font-size: 14;
	padding: 3px;
}
.bread-crumbs {
	display: flex;
}
.custom-pagination {
	position: absolute;
	right: 25px;
	a {
		font-size: 18px;
		padding-left: 10px;
		color: #9E9E9E;
	}
	.active {
		font-size: 18px;
		padding-left: 10px;
		color: #007ACC;
	}
}
.lk-data {
	color: #292929;
}
.dia-head {
	font-size: 20px;
	color: #292929;
	font-weight: 600;
	
}
.dia-btn {
	display: flex;
	margin: 12px;
	padding: 3px;
	margin-top: -2px;
	color: #2d2d2d;
	
	span {
		margin-top: 4px;
		margin-left: 3px;
		padding-right: 5px;
		&:hover {
			color: #007ACC;
		}
	}
	&:hover {
		background: #ECECEC;
		padding: 3px;
		color: #007ACC;
	}
}
.act-cir {
	color: #48B473;
	font-size: 8px!important;
	padding-right: 10px;
}
.ph-cir {
	font-size: 8px!important;
	padding-right: 10px;
	color: #9E9E9E;
}
.allergy-sort {
	color: #2D2D2D;
	padding: 5px;
	margin-left: auto;
	margin-right: 0;
}
.al-sort-doc {
	margin-left: unset!important;
}
.allergy-sortdoc {
	margin-left: auto;
	color: #2D2D2D;
	padding: 5px;
	margin-right: 0px;
}
.browse-btn-wrapper {
	position: absolute;
	overflow: hidden;
	display: inline-block;
	height: 100%;
	width: 100%;
	border: 1px solid #9E9E9E;
	border-radius: 0.325rem;
	font-size: 0.6rem;
	padding: 0.225rem 0.6rem;
	input[type=file] {
		font-size: 100px;
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;
	}
}
.browse-btn {
	color: #007ACC;
	font-size: 12;
	position: absolute;
	top: 0.5rem;
	left: 0.538rem;
}
.selectformContainer {
	position: relative;
	height: 2rem;
	width: 13%;
	margin-bottom: 0.7rem;
	margin-left: auto;
	margin-right: 10px;
}
.select-crt-pt {
	height: 2.25rem;
	width: 100%;
	margin-bottom: 2rem;
}
.sort-cate {
	// padding-right: 10px;
	display: block;
	padding-bottom: 5px;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 23px;

color: #A3A3A3;

}
.sort-inp {
	appearance: none;
	border-radius: 50%;
	width: 12px;
	height: 12px;
	border: 2px solid #999;
	margin-left: 4px;
	position: relative;
	transition: 0.2s all linear;
	top: 4px;
	cursor: pointer;
	&:checked {
		border: 4px solid  #007ACC !important;;
	}
}
.doc-upload {
	display: flex;
	margin: 12px;
	padding: 3px;
	margin-top: -3px;
	margin-left: 7rem;
	color: #2d2d2d;
	position: absolute;
	
	font-size: 14px;
	span {
		margin-top: 4px;
		margin-left: 3px;
		padding-right: 5px;
		&:hover {
			color: #007ACC;
		}
	}
	&:hover {
		background: #ECECEC;
		padding: 3px;
		color: #007ACC;
	}
}
.file-upload {
	&:hover {
		background: #ECECEC;
		color: #007ACC;
	}
}
.cat-label {
	padding-left: 3px;
	vertical-align: sub;
	font-size: 12px;
}
.des-text {
	border: 1px solid #9E9E9E;
	border-radius: 0.325rem;
	height: 80px;
	width: 100%;
	outline: none;
	box-shadow: unset;
	font-size: 0.6rem;
	padding: 0.225rem 0.6rem;
	&:focus {
		border: 1px solid #007ACC;
	}
}
.medrepo-icon {
	font-size: 5rem;
}
.repo-text {
	text-align: center;
	position: relative;
	top: 15px;
	font-size: 12px;
}
.empty-text {
	text-align: center;
	margin-top: 7%;
	
	font-size: 14px;
	line-height: 20px;
	color: #9E9E9E;
	min-height: 120px;
}
.medication-content {
	margin-top: 3rem;
}
.emp-summary-content {
	margin-top: 3.64rem;
}
.dx-content {
	margin-top: 4.2rem;
}
.allergy-content {
	margin-top: 3.1rem;
	img {
		width: 80px;
		height: 80px;
	}
}
.data-vitalstab,.data-vitalmd,.data-vitalallergy {
	background: #F7F8F9;
	border-radius: 5px;
	padding: 10px;
	height: 11.5rem;
}
.complete-btn,.arrow-iconcom {
	padding: 5.69231px;
	gap: 11.38px;
	align-items: flex-end;
	background-color: #007ACC;
	border: none;
	color: white;
	width: 9.2rem;
	height: 2rem;
	padding: 4px;
	text-decoration: none;
	font-size: 16px;
	margin-top: 3rem;
	float: right;
}
.text-complete {
	text-align: left;
}
.doc-icon {
	color: #007ACC;
	font-size: 2.5rem;
}
.pre-head {
	font-weight: 400;
	font-size: 24px;
	line-height: 0px;
	color: #2D2D2D;
	margin-top: 15px;
	
}
.arrow-icon {
	font-size: 1.3rem;
	vertical-align: sub;
	padding-right: 8px;
	color: #fff;
}
.opt-text {
	box-sizing: border-box;
	border: 0.5px solid #9E9E9E;
	border-radius: 6px;
	color: #9E9E9E;
	width: 7.5rem;
	height: 1.5rem;
}
.arrow-iconcom {
	font-size: 12px!important;
	margin: 0px 0px!important;
	width: 7rem!important;
}
// div[role="progressbar"] {
// 	--size: 12rem;
// 	--fg: #48B473;
// 	--bg: #D9D9D9;
// 	--pgPercentage: var(--value);
// 	animation: growProgressBar 3s 1 forwards;
// 	height: 7rem;
// 	margin-top: 12px;
// 	border-radius: 50%;
// 	display: grid;
// 	place-items: center;
// 	background: radial-gradient(closest-side, white 80%, transparent 0 99.9%, white 0),
//       conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
// 	font-family: 'Source Sans 3';
// 	font-weight: 600;
// 	font-size: 18px;
// 	color: #9E9E9E;
// 	vertical-align: middle;
// }
// div[role="progressbar"]::before {
// 	counter-reset: percentage var(--value);
// 	content: counter(percentage) '%';
// }
.num-data {
	margin-bottom: 0px;
	margin-top: 15px;
}
.info-icon {
	color: #007ACC;
	vertical-align: middle;
	font-size: 0.9rem;
	padding-right: 5px;
}
.info-data {
	
	font-size: 0.85rem;
	line-height: 2rem;
	color: #9E9E9E;
}
.form-control {
	color: #2d2d2d;
	font-size: 14px!important;
}
.page-content {
	height: 100vh;
	overflow-y: scroll;
}
.diagnosis-data, .allergy-data, .documents-data {
	padding-bottom: 20px;
}
.recent-data {
	height: 350px;
	overflow: hidden;
	padding-bottom: 20px;
}
.diagnosis-table,.allergy-table, .document-table {
	height: unset;
	display: inline-block;
	width: 100%;
	overflow-y: scroll;
}
.recent-table {
	height: 300px;
	display: inline-block;
	width: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
}
.diagnosis-table {
	&:hover {
		overflow-y: auto;
		transition: all 0.6s linear;
	}
}
.thead-data {
	height: 44px;
	overflow: auto;
	position: sticky;
	top: 0;
}
.nav-pills {
	.nav-link {
		&.active {
			&::after {
				border-bottom: 1px solid #007ACC;
			}
		}
	}
}
.pd-right-0 {
	padding-right: 0px;
}
.vit {
	
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 17px;
	color: #9E9E9E;
	padding-left: 0.75rem;
}
.btn-blue-border {
	padding: 6px 26px;
	border: 1px solid #9E9E9E;
	background: #fff;
	border-radius: 3px;
	&:hover {
		border: 1px solid #007ACC;
		color: #007ACC;
	}
}
.btn-grey-border {
	padding: 6px 26px;
	border: 0px solid #9E9E9E;
	background: #fff;
	border-radius: 3px;
	&:hover {
		border: 1px solid #007ACC;
		color: #007ACC;
	}
}
.delete-btn {
	float: right;
	margin-top: 10px;
}
.delete-confirm {
	h2 {
		color: #292929;
	}
	p {
		font-size: 12px;
	}
}
.mrg-left {
	padding-left: 0px!important;
}
.pad-3 {
	margin-right: 3rem!important;
}
.main-nav .nav-link, .sub-nav .nav-link {
	padding-left: 0px!important;
	padding-right: 0px !important;
}
.ui-datepicker-trigger {
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	color: #aaa;
	z-index: 999;
}
.ui-datepicker {
	z-index: 2!important;
}
.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
	border: 1px solid #007ACC!important;
	background: #007ACC!important;
	color: #fff!important;
}
.psd-request {
	text-align: left!important;
}
.required {
	&::placeholder {
		&:after {
			content: '*'!important;
			color: red!important;
		}
	}
}
.emp-txt {
	text-align: center;
	border: 0px;
}
.pad-6 {
	padding-right: 6rem;
}
.pad-4 {
	padding-right: 4rem;
}
@keyframes growProgressBar {
    0%, 33% { --pgPercentage: 0; }
    100% { --pgPercentage: var(--value); }
  }
  
  @property --pgPercentage {
    syntax: '<number>';
    inherits: false;
    initial-value: 0;
  }

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
  }
                
  @keyframes spin 
  {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
  }

  
    .mobile-menu-icon
    {
        display: none;
        @media (max-width:900px)
        {
            display: block;
        }
  }

  .user-icon
  {
    background: #989797;
    padding: 6px;
    border-radius: 50%;
    color: #fff;
  }
  .main-content
{
    .date-data
    {
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        color: #2D2D2D;
        padding-top: 20px;

    }
    .main-data
    {
        font-weight: 500;
        font-size: 28px;
        line-height: 40px;
        text-align: center;
        color: #2D2D2D;
    }
}
.con-menu
{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 600px;
  margin: auto;
  margin-top: 18px;
  background: #F7F8F9;
  border-radius: 35px;
        .list-group-item {
            background: transparent;
            border: 0px;
            width: 200px;
            color: #007ACC;
            font-weight: 400;
            font-size: 16px;
            line-height: 23px;
			@media(max-width:1200px)
			{
				font-size: 12px;
			}
            .btn:hover {
                color: #007ACC;
                text-decoration: none;
                background-color: transparent;
                border-color: transparent!important;
                border: 0px!important;
            }
            .btn:active,  .btn.show {
                border-color: transparent!important;
                border: 0px!important;
            }
            .menu-content
                {
                    .bld-data
                    {
                        padding:0px 9px;
                        font-weight: 500;
                        font-size: 16px;
                    }
                }
                .line-dt
                {
                    &::after
                    {
                        content: "";
                        position: absolute;
                        right: 0;
                        top: 6px;
                        width: 1px;
                        height: 75%;
                        background: #007ACC;
                    }
                }
        }
}
.prj-data
{
	padding: 12px;
    background: #FFFFFF;
    border: 0.758287px solid #A3A3A3;
    border-radius: 11.3743px;
    margin-top: 40px;
    height: 345px;
    overflow-y: auto;
	padding-top: 8px;
	@media(max-width:1024px)
		{
			height: 330px;
			overflow-y: auto;
		}

    .lin-dt
    {
        width: 100%;
        display: flex;
		border-bottom: 0.5px solid #a3a3a387;
        padding-bottom: 8px;
        h2
        {
			color: #2D2D2D;
			font-size: 24px;
			margin-bottom: 0px;
			line-height: 36px;

        }
        .cst-dropdown
        {
            float: right;
            position: relative;
            right: 0;
            margin-left: auto;
            cursor: pointer;
            .ft-rt
            {
                padding: 5px;
                border: 1px solid #A3A3A3;
                font-size: 36px;
                border-radius: 10px;
            }
            .bordered
            {
                border: 1px solid #A3A3A3;
				box-shadow: unset;
            }
        }
        
    }
    .crt-project
    {
        padding: 60px;
        // min-height: 300px;
        .dsh-border
        {
            padding: 0px;
            border: 2px dashed transparent;
            border-image: url("../assets/img/border.png") 1 round;
            width: 60px;
            height: 60px;
            margin: 0 auto;
            border-radius: 20px;
            svg
            {
                font-size: 40px;
                margin-top: 10px;
                color: #292929;
            }
        }
        a
        {
            h2{
           padding-top: 20px;
           color: #A3A3A3;
            }
        }
        
    }
    
}
.div-create
{
	padding: 20px;
	.crt-prj
	{
		border: 1px solid #fff;
		padding: 5px 16px;
		padding-bottom: 10px;
		border-radius: 10px;
		padding-right: 20px;
		&:hover{
			background: #fff;
			color: #007acc !important;;
		}
		span {
		vertical-align: sub;
		font-size: 16px;
		// padding-left: 6px;
		}
		svg
		{
			vertical-align: middle;
		}
	}
}
.d-search
{
	width: 50%;
	.app-search {
		width: 450px;
		margin-left: auto;
		::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
			color: #A3A3A3;
			opacity: 1; /* Firefox */
		  }
		.form-control {
			height: 34px;
			padding-left: 40px;
			padding-right: 20px;
			-webkit-box-shadow: none;
			box-shadow: none;
			top: 4px;
			margin-top: 0px;
			background: #5C5C5C;
			border: 1px solid #5C5C5C;
			border-radius: 40px;
			color:#fff;
			
		}
		span {
			position: absolute;
			z-index: 10;
			font-size: 16px;
			line-height: 38px;
			/* left: 13px; */
			top: -2px;
			color: var(--bs-gray-600);
			right: 16px;
		}
		
	}
	@media (max-width:1024px)
		{
			.app-search
			{
				width:250px;
			}
			
		}
}

.redborder 
{
	input {
		border: 1px solid #D70015!important;
	}
	label {
		color:  #D70015!important;
	}
	&:focus {
		border: 1px solid #D70015;
		& + .label {
			
			color: #D70015!important;
			
		}
	}
	
}
.redborder:not(:placeholder-shown) + .label
	{
		color:  #D70015!important;
	}
.validation-message
{
	position: fixed;
	right: 0px;
	padding: 15px;
	top: 30%;
	z-index: 999;
	 background: #BC3C47;
	box-shadow: 14px 10px 7px rgba(0, 0, 0, 0.01), 8px 6px 6px rgba(0, 0, 0, 0.03), 3px 2px 4px rgba(0, 0, 0, 0.04), 1px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05);
	border-radius: 10px 0px 0px 10px;
	width:250px;
	p{
		
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 23px;
        color: #FFFFFF;
	}
	button{
		border: 1px solid #FFFFFF;
        border-radius: 10px;
		background: transparent;
		padding: 5px 10px;
		color:#fff;
	}
	a{
		display: block;
		color: #fff;
		text-decoration: underline!important;
		padding-top: 10px;
	}
}

.mobile-menu-icon
{
	svg{
		color: #ccc;
	}
}
.position-relative{
	span{
		svg{
			color: rgba(255, 255, 255, 0.375);
		}
	}
}
.div-create{
	a{
		svg{
			vertical-align: middle!important;
		}
	}
}
.cmp-data
{
		padding: 1px 1px;
		background: #1E823A;
		@media (max-width:600px)
		{
			padding: 0px 0px;
		}
		svg
		{
			vertical-align: middle;
			color:#fff
		}
}
.tx-cen{
	text-align: center!important;
}
.main-dashboard
{
	padding: 8px;
	padding-left: 16px;
	.crt-project
	{
		display: flex;
		padding: 0px;
		// padding-top: 30px;
		padding-top: 15px;
		.dsh-border {
			padding: 0px;
			border: 1px dashed transparent;
			border-image: url(../assets/img/border.png) 1 round;
			width: 45px;
			height: 45px;
			margin: 0;
			border-radius: 20px;
			svg {
				font-size: 20px;
				margin: 12px 12px;
				color: #292929;
			}
			
		}
		a 
			{
				h2 {
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 23px;
					padding-top:10px;
					margin-left:10px;

					color: #A3A3A3;
				}
			}
	}
	.prj-overview{
		display: flex;
		padding: 0px;
		padding-top: 15px;
		height: unset;
		@media(max-width:1024px)
		{
			padding-top: 15px;
		}
		.ovr-data {
			padding: 0px;
			width: 45px;
			height: 45px;
			margin: 0;
			border-radius: 5px;
			background: #9747FF;
			svg {
				font-size: 20px;
				margin: 12px 12px;
				color: #fff;
			}
			
		}
		a 
			{
				h2 {
					font-style: normal;
					font-weight: 400;
					font-size: 16px;
					line-height: 23px;

					/* CLHF Blac */

					color: #2D2D2D;
					margin-left: 10px;
					span{
						display: block;
						font-size: 12px;
						color: #A3A3A3;
					}
				}
			}
	}
}
.activities
{
	.filter
	{
		float: right;
		position: relative;
		right: 0;
		margin-left: auto;
		cursor: pointer;
		svg{
			font-size: 20px;
			color:#2D2D2D;
		}
		label{
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 23px;
			color: #2D2D2D;
			vertical-align: sub;
		

		}
	}
}
.com-progressbar
{
	padding: 0px;
	.completed-progress 
	{
		// --bs-bg-opacity: 1;
		background-color: #1E823A!important;
	}
}
#id-search
{
	display: none;
}
.cnt-fnt
{
	color:#2d2d2d;
	margin-bottom: 0px;
	font-size: 16px;
	label{
		color:#989797;
	}
}
.ft-right
{
	float: right;
	.btn-blue
	{
		border-radius: 12px;
	}
}
.text-left{
	text-align: left;
}