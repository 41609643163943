@media (max-width:600px)
{
    body{
        overflow:scroll;
    }
    .con-menu 
    {
        margin-top: 50px;
        width: unset;
        height: unset;
        .list-group-horizontal
        {
            flex-direction: column;
        }
        .list-group-item
        {
            .line-dt
            {
                &::after
                {
                    display: none;
                }
            }
        }
    }
    
    .logo-main {
        left: 40px!important;
        .logo-txt
    {
        display: none;
    }
    }
    .sm-task-list
    {
        .prj-nme
        {
            font-size: 9px;
            margin-left: 4px;
            padding-left: 7px;
        }
        .track-btn
        {
            font-size: 9px;
        }
        h4{
            font-size: 11px;
        }
    }
    .ft-right
    {
        span{
            font-size: 10px;
            padding-left: 4px;
        }
        .avatar-xs {
            width: 1.25rem;
            height: 1.25rem;
            font-size: 8px;
            .avatar-title{
                width: 1.25rem;
            }
        }
    }
    .summary-tab td {
        padding: 0.3rem 0.1rem;
    }
    .navbar-header 
    {
        .dropdown 
        {
            .dropdown-menu {
                left: unset !important;
            }
        }
    }
    .modal-small {
        width: unset;
    }
    .overview-header 
    {
        .prj-header 
        {
            h4 {
                font-size: 15px;
            }
        }
    }
    .prj-overview 
    {
        .last-item 
        {
            .fc-prf 
            {
                p {
                    width: 50%;
                }
            }
        }
    }
   
    .facility-details 
    {
        .ft-dt{
            P {
                font-size: 10px;
            }
            label {
                font-size: 10px;
            }
        }
    }
    .alc-dt 
    {
        label {
            font-size: 10px;
        }
        p{
            font-size: 12px;
        }
    }
    .pr-over 
    {
        .container-fluid
        {
            .overview-content {
                padding: 0 0 0 15px;
            }
        }
    }
    .prj-overview 
    {
        height: unset;
        .timeline-list-item 
        {
            .st-fnt {
                font-size: 14px;
            }
        }
    }
    .overview-header
    {
        .prj-header 
        {
            .pad-3 {
                margin-right: 0.6rem !important;
            }
        }
    }
    .pr-over {
    overflow-y: scroll;
    }
    .validation-message {
        width: 250px!important;
        top:40%;
    }    
    .header-section 
    {
        h1 {
            font-size: 14px!important;
        }
       h4 {
        font-size: 13px!important;
       }
       .ct-header
        {
            .ct-left {
                width: 60%;
            }
            .ct-right
            {
                width: 40%;
                .log-out {
                  display: block;
                  padding-bottom: 20px;
                  a{
                    font-size: 10px;
                  }
                }
            }
        }
    }
    .cmp-data
    {
        .cus-icon {
            font-size: 12px;
        }
        svg{
            vertical-align: baseline;
        }
    }
    .form-menu 
    {
        .timeline-list-item{
            .nav-item 
            {
                .nav-link span {
                    font-size: 8px!important;
                }
            }
        }
        .timeline 
        {
            .nav-item {
                text-align: center;
                width: 16%;
            }
        }
    }
    
    .overview-content
    {
        .prj-overview
        {
            .col-md-4
            {
                width: 100%!important;
            }
        }
    }
    .pr-over 
    {
        .container-fluid {
           overflow: scroll;
           .overview-header
            {
                    .bt-btm
                    {
                        .col-md-1
                        {
                            width: 7%;
                            .ovr-data
                            {
                                width: 40px;
                                height: 40px;
                                border-radius: 5px;
                                 
                                    svg {
                                        color: #FFFFFF;
                                        font-size: 30px;
                                        margin: 6px 5px;
                                     }
                            }
                        }
                        .col-md-11
                        {
                            width: 93%;
                            .prj-header
                            {
                                .nav-link {
                                    font-size: 10px;

                                }
                            }
                        }
                    }
            }
        }
    }
    #ct-btn-id {
        top: -16px;  
    } 
    .tg-switch {
        margin-right: 16px;
         margin-left: auto;

    }
    .facility-details 
    {
        .prj-edit 
        {
            .dropdown-menu 
            {
                .create-project {
                    padding: 15px;
                    width: 215px;
                }
            }
        }
    }
    .crt-select {
        font-size: 10px;
    }
    .create-project
    {
        .form-control {
            font-size: 10px!important;
        }
    }
    #tsk-list 
    {
        .prj-list td {
            font-size: 6px;
        }
        .prj-btn {
            font-size: 6px;
        }
    }
    .list-task-tb
    {
        .prj-btn svg {
            margin-right: 0px;
        }
        .wit-7,.wit-20,.wit-30
        {
            width: unset;
        }
    }
    .task-content .left-content .list-task-tb td svg {
        font-size: 18px;
        margin-left: 5px;
    }
    .task-content 
    {
        .left-content 
        {
            .list-task-tb 
            {
                td 
                {
                    svg {
                        font-size: 6px;
                            margin-left: 0px;
                        
                    }
                }
            }
            .flex-data 
            {
                span {
                    padding-left: 2px;
                }
                .avatar-xs {
                    font-size: 6px;
                    width: 1rem;
                    height: 1rem;
                  .bg-soft,.bg-orange,.bg-litgreen {
                        width: 1rem;
                    }
                }
            }
        }
    }
    .task-tbl
    {
        .list-task-tb
        {
            th{
                font-size: 8px;
            }
        }
    }
    .doc-custom
    {
        .container-fluid
        {
            .overview-header
            {
                position: fixed!important;
                margin-top: 50px;
            }
        }
    }
    #contentleft-data
    {
        .prj-list
        {
            p{
                padding-top: 30px;
            }
        }
    }
    .form-main 
    {
        .thks-data {
            width: unset!important;
            margin: unset;
        }
    }
    .wt-70 {
        width: 50%;
    }
    .document-data {
         padding: 5px!important; 
         .rit-border
         {
            padding-left: 35px;
         }
         .action-btn 
         {
            button 
            {
                svg {
                    color: #A3A3A3;
                    font-size: 14px;
                }
            }
         }
         .finances-data 
         {
            .tab-fin 
            {
                .tab-cl 
                {
                    .clr-grey 
                    {
                        .cr-gry {                           
                            font-size: 12px;
                            
                        }
                    }
                }
              .fin-table 
              {
                .fin-tb-body 
                {
                    tr{
                        td{
                            font-size: 12px;
                        }
                    }
                }
              }
            }
         }
    }
     .guestlogin
        {
            .doodle-dl
            {
                height:auto;
                max-width: 100%;
                bottom: 30%;
            }
          .gl-right 
          {
            .frm-sec {
                top: 10%;
            }
          }
        }
        .sigCanvas {
            height: 200px;
            width: 320px;
        }
        #sig-clearBtn
        {
            display: block;
            border: 0px;
            outline: 0px;
        }
        .signature-pad
        {
            width: unset;
        }
        .sticky-data
        {
            .box-content 
            {
                .box {
                    text-align: center;
                    .progress-bar {
                        margin-left: unset;
                        width: unset;
                    }
                }
            }
        }
        .form-menu 
        {
            .timeline {
                padding-left: 10px;
            }
        }
}


@media(max-width:1024px)
{
    .document-data {
        padding: 45px;
    }
    #contentleft-data {
     overflow-y: scroll; 
    }
    .pr-over 
    {
        .container-fluid {
           overflow: scroll;
           .overview-header
            {
               
                    padding: 20px 0px 0px 6px;
                    position: sticky;
                    top: 0;
                    z-index: 999;
                    background: #fff;
                    border-bottom: 1px solid #a3a3a338;
                    .bt-btm
                    {
                        .col-md-1
                        {
                            width: 7%;
                            .ovr-data
                            {
                                width: 40px;
                                height: 40px;
                                border-radius: 5px;
                                 
                                    svg {
                                        color: #FFFFFF;
                                        font-size: 30px;
                                        margin: 6px 5px;
                                     }
                            }
                        }
                        .col-md-11
                        {
                            width: 93%;
                            .prj-header
                            {
                                .nav-link {
                                    font-size: 10px;

                                }
                            }
                        }
                    }
            }
        }
    }
    .doc-custom
    {
        .container-fluid
        {
            overflow: scroll;
        }
       .document-customheader
       {
        h3 {
            font-size: 20px;
        }
       }
    }
    .event-styles {
        font-size: 8px !important;
    }
    .event-container
    {
        .avatar-xs {
            width: 0.8rem;
            height: 0.8rem;
            font-size: 6px;
            margin-right: 5px !important;
        }
    }
    .event-container
    {
        .avatar-xs
        {
            .bg-soft,.bg-litgreen,.bg-orange {
                width: 0.8rem;
            }
        }
    }
    .logo-main {
        left: 70px!important;
    }
    .fc-hd {
        font-size: 12px !important;
    }
    .text-left{
        font-size: 12px !important;
    }
    .data h6 span {
        font-size: 12px !important;
    }
    .data h6 {
        font-size: 12px !important;
    }
    .esignImg{
        margin-left: 0 !important;
    }
    .pdf-tbl thead th{
        font-size: 12px !important;
    }
    .fnt-s-12 tr td {
        font-size: 10px !important;
    }
}