:root {
	--bs-heading-bg: #fff;
	--bs-heading-bg-main: #2D2D2D;
	--bs-topbar-search-bg: #f3f3f9;
	--bs-header-item-color: #555b6d;
	--bs-boxed-body-bg: #ebebf4;
	--bs-topnav-bg: #fff;
	--bs-menu-item-color: #545a6d;
	--bs-heading-dark-bg: #006EB8;
	--bs-input-border-color: #ced4da;
	--bs-input-focus-border-color: #b1bbc4;
	--bs-form-check-input-border: rgba(0, 0, 0, 0.1);
	--bs-footer-bg: #f2f2f5;
	--bs-footer-color: #74788d;
	--bs-custom-white: #fff;
	--bs-hr-border-color: gray-400;
}

[data-layout-mode=dark] {
	--bs-gray-100: #212529;
	--bs-gray-200: #006EB8;
	--bs-gray-300: #32394e;
	--bs-gray-400: #a6b0cf;
	--bs-gray-500: #bfc8e2;
	--bs-gray-600: #c3cbe4;
	--bs-gray-700: #f6f6f6;
	--bs-gray-800: #eff2f7;
	--bs-gray-900: #f8f9fa;
	--bs-body-bg: #222736;
	--bs-body-bg-rgb: rgb(34, 39, 54);
	--bs-body-color-rgb: 166, 176, 207;
	--bs-body-color: #a6b0cf;
	--bs-heading-color: #f6f6f6;
	--bs-border-color: #32394e;
	--bs-custom-white: #006EB8;
	--bs-input-border-color: #32394e;
	--bs-input-focus-border-color: #3a425a;
	--bs-hr-border-color: #32394e;
	--bs-form-check-input-border: #3a425a;
	--bs-light: #32394e;
	--bs-light-rgb: 50, 57, 78;
	--bs-dark: #f6f6f6;
	--bs-dark-rgb: 246, 246, 246;
	--bs-heading-bg: #262b3c;
	--bs-header-item-color: #a6b0cf;
	--bs-topbar-search-bg: #006EB8;
	--bs-heading-dark-bg: #556ee6;
	--bs-boxed-body-bg: #32394f;
	--bs-topnav-bg: #282e3f;
	--bs-menu-item-color: #a6b0cf;
	--bs-footer-bg: #262b3c;
	--bs-footer-color: #a6b0cf;

	.card {
		--bs-card-bg: #006EB8;
	}

	.list-group {
		--bs-list-group-bg: #006EB8;
		--bs-list-group-color: #f8f9fa;
		--bs-list-group-border-color: #32394e;
	}

	.btn-light {
		--bs-btn-color: #c3cbe4;
		--bs-btn-bg: #32394e;
		--bs-btn-border-color: #32394e;
	}

	.btn-outline-light {
		--bs-btn-color: #c3cbe4;
		--bs-btn-border-color: #32394e;
	}

	.btn-dark {
		--bs-btn-bg: #a6b0cf;
		--bs-btn-border-color: #a6b0cf;
		--bs-btn-hover-bg: #a6b0cf;
	}

	.btn-outline-dark {
		--bs-btn-color: #a6b0cf;
		--bs-btn-border-color: #a6b0cf;
	}

	.dropdown-menu {
		--bs-dropdown-bg: #006EB8;
		--bs-dropdown-link-color: #a6b0cf;
		--bs-dropdown-border-color: #32394e;
		--bs-dropdown-border-width: 1px;
		--bs-dropdown-divider-bg: #32394e;
		--bs-dropdown-link-hover-bg: #32394e;
		--bs-dropdown-link-hover-color: #e9ecef;
		--bs-dropdown-link-active-bg: #32394e;
		--bs-dropdown-link-active-color: #e9ecef;
	}

	.modal {
		--bs-modal-bg: #006EB8;
	}

	.nav-tabs {
		--bs-nav-tabs-link-active-bg: #006EB8;
		--bs-nav-tabs-link-active-border-color: #3a425a;
		--bs-nav-tabs-border-color: #3a425a;
		--bs-nav-tabs-link-hover-border-color: #3a425a;
		--bs-nav-tabs-link-active-color: #556ee6;
	}

	.accordion {
		--bs-accordion-active-bg: rgba(85, 110, 230, 0.1);
	}

	.toast {
		--bs-toast-header-bg: #006EB8;
		--bs-toast-header-color: #c3cbe4;
		--bs-toast-bg: #006EB8;
	}

	.table {
		--bs-table-striped-bg: rgba(191, 200, 226, 0.05);
		--bs-table-hover-bg: rgba(191, 200, 226, 0.05);
		--bs-table-active-bg: rgba(191, 200, 226, 0.05);
	}

	.table-light {
		--bs-table-color: #a6b0cf;
		--bs-table-bg: #32394e;
		--bs-table-border-color: #32394e;
	}

	.table-dark {
		--bs-table-color: #a6b0cf;
		--bs-table-bg: #32394e;
		--bs-table-border-color: #3a425a;
	}

	.accordion-button {
		&:not(.collapsed) {
			color: var(--bs-accordion-button-active-color);
		}

		&:not(.collapsed):after {
			background-image: url("data:image/svg+xml, %3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
		}

		&:after {
			background-image: url("data:image/svg+xml, %3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23adb5bd'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
		}
	}

	.btn-close {
		-webkit-filter: invert(1) grayscale(100%) brightness(200%);
		filter: invert(1) grayscale(100%) brightness(200%);
	}

	.btn-close-white {
		-webkit-filter: invert(1) grayscale(100%) brightness(0);
		filter: invert(1) grayscale(100%) brightness(0);
	}

	.form-select {
		background-image: url("data:image/svg+xml, %3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ced4da' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
	}
}

[data-layout-mode=dark] .btn-light,
[data-layout-mode=dark] .btn-outline-light {
	--bs-btn-hover-color: #c3cbe4;
	--bs-btn-hover-bg: #32394e;
	--bs-btn-hover-border-color: #32394e;
	--bs-btn-active-color: #c3cbe4;
	--bs-btn-active-bg: #32394e;
	--bs-btn-active-border-color: #32394e;
	--bs-btn-focus-shadow-rgb: 50, 57, 78;
}

[data-layout-mode=dark] .btn-dark,
[data-layout-mode=dark] .btn-outline-dark {
	--bs-btn-hover-bg: #a6b0cf;
	--bs-btn-hover-border-color: #a6b0cf;
	--bs-btn-active-bg: #a6b0cf;
	--bs-btn-active-border-color: #a6b0cf;
	--bs-btn-focus-shadow-rgb: 195, 203, 228;
}

[data-layout-mode=dark] .offcanvas,
[data-layout-mode=dark] .offcanvas-lg,
[data-layout-mode=dark] .offcanvas-md,
[data-layout-mode=dark] .offcanvas-sm,
[data-layout-mode=dark] .offcanvas-xl,
[data-layout-mode=dark] .offcanvas-xxl {
	--bs-offcanvas-bg: #006EB8;
}

#page-topbar {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1002;
	background-color: #333333;
	-webkit-box-shadow: 0 .75rem 1.5rem rgba(18, 38, 63, .03);
	box-shadow: 0 .75rem 1.5rem rgba(18, 38, 63, .03);
}

.navbar-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: justify;
	-webkit-box-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 0 auto;
	height: 50px;
	padding: 0 calc(24px * .5) 0 0;

	.dropdown {
		.show {
			&.header-item {
				background-color: transparent;
				height: 50px;
				margin-top: 10px;

				svg {
					color: #007ACC;
				}
			}
		}
	}
}

// .dropdown-menu {
// 	&.show {
// 		top: 90%;
// 	}
// }

.navbar-header
{
	.dropdown-menu {
		&.show {
			top: 90%!important;
		}
	}
}
.navbar-brand-box {
	padding: 0 1.5rem;
	text-align: center;
	width: 250px;
}

.logo {
	line-height: 70px;

	.logo-sm {
		display: none;
	}
}

.logo-light {
	display: none;
}

.app-search {
	padding: calc(32px * .5) 0;
	// .form-control {
	// 	height: 34px;
	// 	padding-left: 40px;
	// 	padding-right: 20px;
	// 	background-color: transparent;
	// 	-webkit-box-shadow: none;
	// 	box-shadow: none;
	// 	border-radius: 10px;
	// 	border: 1px solid #9e9e9e8f;
	// 	top: 4px;
	// 	margin-top: 2px;
	// }
	// span {
	// 	position: absolute;
	// 	z-index: 10;
	// 	font-size: 16px;
	// 	line-height: 38px;
	// 	// left: 13px;
	// 	top: -2px;
	// 	color: var(--bs-gray-600);
	// }
}

.megamenu-list {
	li {
		position: relative;
		padding: 5px 0;

		a {
			color: var(--bs-body-color);
		}
	}
}

.page-content {
	padding: calc(50px + 14px) calc(24px * .75) 60px calc(24px * .75);
	background-color: #fff;
	padding-left: 10px;
}

.header-item {
	height: 70px;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	color: var(--bs-header-item-color);
	border: 0;
	border-radius: 0;

	&:hover {
		color: var(--bs-header-item-color);
	}
}

.header-profile-user {
	height: 36px;
	width: 36px;
	background-color: var(--bs-gray-300);
	padding: 3px;
}

.noti-icon {
	i {
		font-size: 22px;
		color: var(--bs-header-item-color);
	}

	.badge {
		position: absolute;
		top: 12px;
		right: 4px;
	}
}

.notification-item {
	.d-flex {
		padding: .75rem 1rem;

		&:hover {
			background-color: var(--bs-gray-300);
		}
	}
}

.dropdown-icon-item {
	display: block;
	border-radius: 3px;
	line-height: 34px;
	text-align: center;
	padding: 15px 0 9px;
	display: block;
	border: 1px solid transparent;
	color: var(--bs-gray-600);

	img {
		height: 24px;
	}

	span {
		display: block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&:hover {
		border-color: var(--bs-border-color);
	}
}

.fullscreen-enable {
	[data-bs-toggle=fullscreen] {
		.bx-fullscreen {
			&::before {
				content: "\ea3f";
			}
		}
	}
}

body[data-topbar=dark] {
	#page-topbar {
		background-color: var(--bs-heading-dark-bg);
	}

	.navbar-header {
		.dropdown {
			.show {
				&.header-item {
					background-color: rgba(255, 255, 255, .05);
				}
			}
		}

		.waves-effect {
			.waves-ripple {
				background: rgba(255, 255, 255, .4);
			}
		}
	}

	.header-item {
		color: #e9ecef;

		&:hover {
			color: #e9ecef;
		}
	}

	.header-profile-user {
		background-color: rgba(255, 255, 255, .25);
	}

	.noti-icon {
		i {
			color: #e9ecef;
		}
	}

	.logo-dark {
		display: none;
	}

	.logo-light {
		display: block;
	}

	.app-search {
		.form-control {
			background-color: rgba(var(--bs-topbar-search-bg), .07);
			color: #fff;
		}
	}
}

body[data-topbar=dark] .app-search input.form-control::-webkit-input-placeholder,
body[data-topbar=dark] .app-search span {
	color: rgba(255, 255, 255, .5);
}

body[data-sidebar=dark] {
	.navbar-brand-box {
		background: #006EB8;
	}

	.logo-dark {
		display: none;
	}

	.logo-light {
		display: block;
	}

	.vertical-menu {
		background: #007ACC;
	}

	#sidebar-menu {
		ul {
			li {
				a {
					color: #fff;

					i {
						color: #6a7187;
					}

					&:hover {
						color: #fff;

						i {
							color: #fff;
						}
					}
				}

				ul {
					&.sub-menu {
						li {
							a {
								color: #fff;

								&:hover {
									color: #fff;
								}
							}
						}

						.mm-active {
							a {
								color: #ffffff;
								background-color: #048FB8;
							}
						}
					}
				}
			}
		}
	}

	.mm-active {
		color: #fff !important;

		&>a {
			color: #fff !important;

			i {
				color: #fff !important;
			}
		}

		&>i {
			color: #fff !important;
		}

		.active {
			color: #fff !important;
			background: #006EB8;
			box-shadow: 0px 35px 14px rgba(0, 0, 0, 0.01), 0px 19px 12px rgba(0, 0, 0, 0.03), 0px 9px 9px rgba(0, 0, 0, 0.04), 0px 2px 5px rgba(0, 0, 0, 0.05);
			border-radius: 0px;

			i {
				color: #fff !important;
			}
		}
	}

	.menu-title {
		color: #6a7187;
	}
}

body[data-layout=horizontal] {
	.navbar-brand-box {
		width: auto;
	}

	.page-content {
		margin-top: 70px;
		padding: calc(55px + 24px) calc(24px * .5) 60px calc(24px * .5);
	}

	.footer {
		left: 0 !important;
	}

	.main-content {
		margin-left: 0 !important;
	}
}

.page-title-box {
	padding-bottom: 24px;

	.breadcrumb {
		background-color: transparent;
		padding: 0;
	}

	h4 {
		text-transform: uppercase;
		font-weight: 600;
		font-size: 16px !important;
	}
}

.footer {
	bottom: 0;
	padding: 20px calc(24px * .75);
	position: absolute;
	right: 0;
	color: var(--bs-footer-color);
	left: 250px;
	height: 60px;
	background-color: var(--bs-footer-bg);
}

.vertical-collpsed {
	.footer {
		left: 70px;
	}

	.main-content {
		margin-left: 55px;
	}

	.navbar-brand-box {
		width: 60px !important;
	}

	.logo {
		span {
			&.logo-lg {
				display: none;
			}

			&.logo-sm {
				display: block;
			}
		}
	}

	.vertical-menu {
		position: absolute;
		width: 55px !important;
		z-index: 5;

		.simplebar-scrollbar {
			display: none !important;
		}

		.simplebar-offset {
			bottom: 0 !important;
		}

		#sidebar-menu {
			.nav {
				&.collapse {
					height: inherit !important;
				}
			}

			.has-arrow {
				&:after {
					display: none;
				}
			}

			&>ul {
				&>li {
					position: relative;
					white-space: nowrap;

					&>a {
						padding: 10px 15px;
						min-height: 30px;
						-webkit-transition: none;
						transition: none;
						border-top-right-radius: 10px;

						i {
							font-size: 1.45rem;
							margin-left: 4px;
						}

						span {
							display: none;
							padding-left: 15px;
						}
					}

					&:hover {
						&>a {
							position: relative;
							width: calc(170px + 30px);
							color: #556ee6;
							background-color: #f5f5f5;
							-webkit-transition: none;
							transition: none;

							i {
								color: #556ee6;
							}

							span {
								display: inline;
							}
						}

						&>ul {
							display: block;
							left: 55px;
							position: absolute;
							width: 145px;
							height: auto !important;
							-webkit-box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, .1);
							box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, .1);

							ul {
								-webkit-box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, .1);
								box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, .1);
							}

							a {
								-webkit-box-shadow: none;
								box-shadow: none;
								padding: 5px 8px;
								position: relative;
								width: 145px;
								z-index: 6;
								color: #545a6d;

								&:hover {
									color: #383c40;
								}
							}
						}
					}
				}

				ul {
					padding: 0px 0;
					z-index: 9999;
					display: none;
					background-color: #fff;

					li {
						&:hover {
							&>ul {
								display: block;
								left: 190px;
								height: auto !important;
								margin-top: -36px;
								position: absolute;
								width: 190px;
							}
						}

						&>a {
							span {
								&.pull-right {
									position: absolute;
									right: 20px;
									top: 12px;
									-webkit-transform: rotate(270deg);
									transform: rotate(270deg);
								}
							}
						}

						&.active {
							a {
								color: #f8f9fa;
							}
						}
					}
				}
			}
		}
	}

	#layout-wrapper {
		min-height: 100vh;
	}
}

.right-bar {
	background-color: var(--bs-custom-white);
	-webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, .06), 0 1px 0 0 rgba(0, 0, 0, .02);
	box-shadow: 0 0 24px 0 rgba(0, 0, 0, .06), 0 1px 0 0 rgba(0, 0, 0, .02);
	display: block;
	position: fixed;
	-webkit-transition: all .2s ease-out;
	transition: all .2s ease-out;
	width: 280px;
	z-index: 9999;
	float: right !important;
	right: -290px;
	top: 0;
	bottom: 0;

	.right-bar-toggle {
		background-color: var(--bs-dark);
		height: 24px;
		width: 24px;
		line-height: 24px;
		display: block;
		color: var(--bs-gray-200);
		text-align: center;
		border-radius: 50%;

		&:hover {
			background-color: var(--bs-dark);
		}
	}
}

.rightbar-overlay {
	background-color: rgba(52, 58, 64, .55);
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: none;
	z-index: 9998;
	-webkit-transition: all .2s ease-out;
	transition: all .2s ease-out;
}

.right-bar-enabled {
	.right-bar {
		right: 0;
	}

	.rightbar-overlay {
		display: block;
	}
}

.metismenu {
	margin: 0;

	li {
		display: block;
		width: 100%;

		&>a {
			&:before {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				width: 2px;
				height: 100%;
				background: 0 0;
			}
		}

		&.mm-active {
			&>a {
				&:before {
					background-color: #fff;
				}
			}
		}
	}

	.mm-collapse {
		display: none;

		&:not(.mm-show) {
			display: none;
		}

		&.mm-show {
			display: block;
		}
	}

	.mm-collapsing {
		position: relative;
		height: 0;
		overflow: hidden;
		-webkit-transition-timing-function: ease;
		transition-timing-function: ease;
		-webkit-transition-duration: .35s;
		transition-duration: .35s;
		-webkit-transition-property: height, visibility;
		transition-property: height, visibility;
	}
}

.vertical-menu {
	width: 170px;
	z-index: 1001;
	background: #fff;
	bottom: 0;
	margin-top: 0;
	position: fixed;
	top: 50px;
	-webkit-box-shadow: 0 .75rem 1.5rem rgba(18, 38, 63, .03);
	box-shadow: 0 .75rem 1.5rem rgba(18, 38, 63, .03);
}

.main-content {
	margin-left: 170px;
	overflow: hidden;

	.content {
		padding: 0 15px 10px 15px;
		margin-top: 70px;
	}
}

#sidebar-menu {
	padding: 10px 0 30px 0;

	.mm-active {
		&>.has-arrow {
			&:after {
				-webkit-transform: rotate(-180deg);
				transform: rotate(-180deg);
			}
		}
	}

	.has-arrow {
		&:after {
			content: "\f0140";
			font-family: "Material Design Icons";
			display: block;
			float: right;
			-webkit-transition: -webkit-transform .2s;
			transition: -webkit-transform .2s;
			transition: transform .2s;
			transition: transform .2s, -webkit-transform .2s;
			font-size: 1rem;
		}
	}

	ul {
		li {
			a {
				display: block;
				padding: .425rem 1rem;
				color: #ffffff;
				position: relative;
				font-size: 13px;
				-webkit-transition: all .4s;
				transition: all .4s;

				span {
					display: inline-block;
					min-width: 1.75rem;
					padding-bottom: .125em;
					line-height: 1.40625rem;
					vertical-align: sub;
					font-weight: 300;
					font-size: 14px;
					line-height: 23px;
					color: #ffffffa6;
					-webkit-transition: all .4s;
					transition: all .4s;
					margin-left: 5px;
				}

				svg {
					color: #ffffffa6;
				}

				&:hover {
					span {
						color: #fff;
					}

					svg {
						color: #fff;
					}
				}
			}

			.badge {
				margin-top: 4px;
			}

			ul {
				&.sub-menu {
					padding: 0;

					li {
						a {
							padding: 0.2rem 1.5rem 0.3rem 3.5rem;
							font-size: 13px;
							color: #545a6d;

							&:hover {
								color: #383c40;
							}
						}

						ul {
							&.sub-menu {
								padding: 0;

								li {
									a {
										padding: .4rem 1.5rem .4rem 4.5rem;
										font-size: 13px;
									}
								}
							}
						}
					}
				}
			}
		}

		.mm-active {
			&>a {
				svg {
					color: #fff;
				}
			}

			.has-arrow {
				&:after {
					color: #ffffff;
				}
			}
		}

		&>.mm-active {
			&>a {
				&>span {
					color: #fff;
				}
			}
		}

		.has-arrow {
			&:after {
				color: #ffffffa6;
			}
		}
	}
}

.menu-title {
	padding: 12px 20px !important;
	letter-spacing: .05em;
	pointer-events: none;
	cursor: default;
	font-size: 11px;
	text-transform: uppercase;
	color: #7f8387;
	font-weight: 600;
}

.mm-active {
	color: #556ee6 !important;

	&>a {
		color: #556ee6 !important;

		i {
			color: #556ee6 !important;
		}
	}

	.active {
		color: #556ee6 !important;

		i {
			color: #556ee6 !important;
		}
	}

	&>i {
		color: #556ee6 !important;
	}
}

.vertical-collpsed .vertical-menu .simplebar-content-wrapper,
.vertical-collpsed .vertical-menu .simplebar-mask {
	overflow: visible !important;
}

.vertical-collpsed .vertical-menu #sidebar-menu .badge,
.vertical-collpsed .vertical-menu #sidebar-menu .collapse.in,
.vertical-collpsed .vertical-menu #sidebar-menu .menu-title {
	display: none !important;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a:active,
.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a:focus,
.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a:hover {
	color: #383c40;
}

body[data-sidebar=dark].vertical-collpsed {
	.vertical-menu {
		#sidebar-menu {
			&>ul {
				&>li {
					&:hover {
						&>a {
							background: #006EB8;
							color: #fff;

							i {
								color: #fff;
							}
						}

						&>ul {
							a {
								color: #fff;

								&:hover {
									color: #fff;
								}
							}
						}
					}
				}

				ul {
					background-color: #006EB8;
					border-bottom-right-radius: 10px;
				}
			}

			ul {
				li {
					&.mm-active {
						.active {
							color: #fff !important;

							i {
								color: #fff !important;
							}
						}
					}
				}
			}
		}
	}
}

body[data-sidebar-size=small] {
	.navbar-brand-box {
		width: 160px;
	}

	.vertical-menu {
		width: 160px;
		text-align: center;
	}

	.main-content {
		margin-left: 160px;
	}

	.footer {
		left: 160px;
	}

	#sidebar-menu {
		ul {
			li {
				&.menu-title {
					background-color: #2e3548;
				}

				a {
					i {
						display: block;
					}
				}

				ul {
					&.sub-menu {
						li {
							a {
								padding-left: 1.5rem;
							}

							ul {
								&.sub-menu {
									li {
										a {
											padding-left: 1.5rem;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

body[data-sidebar-size=small] .vertical-menu .badge,
body[data-sidebar-size=small] .vertical-menu .has-arrow:after {
	display: none !important;
}

body[data-sidebar-size=small].vertical-collpsed {
	.main-content {
		margin-left: 70px;
	}

	.vertical-menu {
		#sidebar-menu {
			text-align: left;

			&>ul {
				&>li {
					&>a {
						i {
							display: inline-block;
						}
					}
				}
			}
		}
	}

	.footer {
		left: 70px;
	}
}

body[data-sidebar=colored] {
	.vertical-menu {
		background-color: #556ee6;
	}

	.navbar-brand-box {
		background-color: #556ee6;

		.logo-dark {
			display: none;
		}

		.logo-light {
			display: block;
		}
	}

	.mm-active {
		color: #fff !important;

		&>a {
			color: #fff !important;

			i {
				color: #fff !important;
			}
		}
	}

	#sidebar-menu {
		ul {
			li {
				&.menu-title {
					color: rgba(255, 255, 255, .6);
				}

				a {
					color: rgba(255, 255, 255, .6);

					i {
						color: rgba(255, 255, 255, .6);
					}

					&.waves-effect {
						.waves-ripple {
							background: rgba(255, 255, 255, .1);
						}
					}

					&:hover {
						color: #fff;

						i {
							color: #fff;
						}
					}
				}

				ul {
					&.sub-menu {
						li {
							a {
								color: rgba(255, 255, 255, .5);

								&:hover {
									color: #fff;
								}
							}
						}
					}
				}
			}
		}
	}
}

body[data-sidebar=colored] .mm-active .active,
body[data-sidebar=colored] .mm-active>i {
	color: #fff !important;
}

body[data-sidebar=colored].vertical-collpsed {
	.vertical-menu {
		#sidebar-menu {
			&>ul {
				&>li {
					&:hover {
						&>a {
							background-color: #5e76e7;
							color: #fff;

							i {
								color: #fff;
							}
						}
					}
				}
			}

			ul {
				li {
					&.mm-active {
						.active {
							color: #556ee6 !important;
						}
					}

					ul {
						&.sub-menu {
							li {
								a {
									&:hover {
										color: #556ee6;
									}
								}

								&.mm-active {
									color: #556ee6 !important;

									&>a {
										color: #556ee6 !important;

										i {
											color: #556ee6 !important;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.topnav {
	background: var(--bs-topnav-bg);
	padding: 0 calc(24px * .5);
	-webkit-box-shadow: 0 .75rem 1.5rem rgba(18, 38, 63, .03);
	box-shadow: 0 .75rem 1.5rem rgba(18, 38, 63, .03);
	margin-top: 70px;
	position: fixed;
	left: 0;
	right: 0;
	z-index: 100;

	.topnav-menu {
		margin: 0;
		padding: 0;
	}

	.navbar-nav {
		.nav-link {
			font-size: 14px;
			position: relative;
			padding: 1rem 1.3rem;
			color: var(--bs-menu-item-color);

			i {
				font-size: 15px;
			}
		}

		.dropdown-item {
			color: var(--bs-menu-item-color);
		}

		.nav-item {
			.nav-link {
				&.active {
					color: #556ee6;
				}
			}
		}

		.dropdown {
			&.active {
				&>a {
					color: #556ee6;
					background-color: transparent;
				}
			}
		}
	}
}

.topnav .navbar-nav .nav-link:focus,
.topnav .navbar-nav .nav-link:hover {
	color: #556ee6;
	background-color: transparent;
}

.topnav .navbar-nav .dropdown-item.active,
.topnav .navbar-nav .dropdown-item:hover {
	color: #556ee6;
}

.arrow-down {
	display: inline-block;

	&:after {
		border-color: initial;
		border-style: solid;
		border-width: 0 0 1px 1px;
		content: "";
		height: .4em;
		display: inline-block;
		right: 5px;
		top: 50%;
		margin-left: 10px;
		-webkit-transform: rotate(-45deg) translateY(-50%);
		transform: rotate(-45deg) translateY(-50%);
		-webkit-transform-origin: top;
		transform-origin: top;
		-webkit-transition: all .3s ease-out;
		transition: all .3s ease-out;
		width: .4em;
	}
}

body[data-layout=horizontal][data-topbar=colored] {
	#page-topbar {
		background-color: #556ee6;
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	.logo-dark {
		display: none;
	}

	.logo-light {
		display: block;
	}

	.app-search {
		.form-control {
			background-color: rgba(var(--bs-topbar-search-bg), .07);
			color: #fff;
		}
	}

	.header-item {
		color: #e9ecef;

		&:hover {
			color: #e9ecef;
		}
	}

	.navbar-header {
		.dropdown {
			.show {
				&.header-item {
					background-color: rgba(255, 255, 255, .1);
				}
			}
		}

		.waves-effect {
			.waves-ripple {
				background: rgba(255, 255, 255, .4);
			}
		}
	}

	.noti-icon {
		i {
			color: #e9ecef;
		}
	}
}

body[data-layout=horizontal][data-topbar=colored] .app-search input.form-control::-webkit-input-placeholder,
body[data-layout=horizontal][data-topbar=colored] .app-search span {
	color: rgba(255, 255, 255, .5);
}

body[data-layout-size=boxed] {
	background-color: var(--bs-boxed-body-bg);

	#layout-wrapper {
		background-color: var(--bs-body-bg);
		max-width: 1300px;
		margin: 0 auto;
		-webkit-box-shadow: 0 .75rem 1.5rem rgba(18, 38, 63, .03);
		box-shadow: 0 .75rem 1.5rem rgba(18, 38, 63, .03);
	}

	#page-topbar {
		max-width: 1300px;
		margin: 0 auto;
	}

	.footer {
		margin: 0 auto;
		max-width: calc(1300px - 250px);
	}
}

body[data-layout-size=boxed].vertical-collpsed {
	.footer {
		max-width: calc(1300px - 70px);
	}
}

body[data-layout=horizontal][data-layout-size=boxed] #layout-wrapper,
body[data-layout=horizontal][data-layout-size=boxed] #page-topbar,
body[data-layout=horizontal][data-layout-size=boxed] .footer {
	max-width: 100%;
}

body[data-layout=horizontal][data-layout-size=boxed] .container-fluid,
body[data-layout=horizontal][data-layout-size=boxed] .navbar-header {
	max-width: 1300px;
}

.avatar-xs {
	height: 2rem;
	width: 2rem;
}

.avatar-sm {
	height: 3rem;
	width: 3rem;
}

.avatar-md {
	height: 4.5rem;
	width: 4.5rem;
}

.avatar-lg {
	height: 6rem;
	width: 6rem;
}

.avatar-xl {
	height: 7.5rem;
	width: 7.5rem;
}

.avatar-title {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #556ee6;
	color: #fff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-weight: 500;
	height: 100%;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 100%;
}

.avatar-group {
	padding-left: 12px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;

	.avatar-group-item {
		margin-left: -12px;
		border: 2px solid var(--bs-custom-white);
		border-radius: 50%;
		-webkit-transition: all .2s;
		transition: all .2s;

		&:hover {
			position: relative;
			-webkit-transform: translateY(-2px);
			transform: translateY(-2px);
		}
	}
}

.custom-accordion {
	.accordion-list {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		border-radius: 7px;
		background-color: var(--bs-gray-300);
		padding: 12px 20px;
		color: var(--bs-body-color);
		font-weight: 600;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;

		&.collapsed {
			i {
				&.accor-plus-icon {
					&:before {
						content: "\f0415";
					}
				}
			}
		}

		.accor-plus-icon {
			display: inline-block;
			font-size: 16px;
			height: 24px;
			width: 24px;
			line-height: 22px;
			background-color: var(--bs-custom-white);
			text-align: center;
			border-radius: 50%;
		}
	}

	a {
		&.collapsed {
			i {
				&.accor-down-icon {
					&:before {
						content: "\f0140";
					}
				}
			}
		}
	}

	.card-body {
		color: var(--bs-gray-600);
	}
}

.font-size-10 {
	font-size: 10px !important;
}

.font-size-11 {
	font-size: 11px !important;
}

.font-size-12 {
	font-size: 12px !important;
}

.font-size-13 {
	font-size: 13px !important;
}

.font-size-14 {
	font-size: 14px !important;
}

.font-size-15 {
	font-size: 15px !important;
}

.font-size-16 {
	font-size: 16px !important;
}

.font-size-17 {
	font-size: 17px !important;
}

.font-size-18 {
	font-size: 18px !important;
}

.font-size-20 {
	font-size: 20px !important;
}

.font-size-22 {
	font-size: 22px !important;
}

.font-size-24 {
	font-size: 24px !important;
}

.fw-medium {
	font-weight: 500;
}

.social-list-item {
	height: 2rem;
	width: 2rem;
	line-height: calc(2rem - 4px);
	display: block;
	border: 2px solid #adb5bd;
	border-radius: 50%;
	color: #adb5bd;
	text-align: center;
	-webkit-transition: all .4s;
	transition: all .4s;

	&:hover {
		color: #74788d;
		background-color: #eff2f7;
	}
}

.w-xs {
	min-width: 80px;
}

.w-sm {
	min-width: 95px;
}

.w-md {
	min-width: 110px;
}

.w-lg {
	min-width: 140px;
}

.w-xl {
	min-width: 160px;
}

.alert-dismissible {
	.btn-close {
		font-size: 10px;
		padding: 1.05rem 1.25rem;
		background: transparent url("data:image/svg+xml, %3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
	}
}

#preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #fff;
	z-index: 9999;
}

#status {
	width: 40px;
	height: 40px;
	position: absolute;
	left: 50%;
	top: 50%;
	margin: -20px 0 0 -20px;
}

.spinner-chase {
	margin: 0 auto;
	width: 40px;
	height: 40px;
	position: relative;
	-webkit-animation: spinner-chase 2.5s infinite linear both;
	animation: spinner-chase 2.5s infinite linear both;
}

.chase-dot {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	-webkit-animation: chase-dot 2s infinite ease-in-out both;
	animation: chase-dot 2s infinite ease-in-out both;

	&:before {
		content: "";
		display: block;
		width: 25%;
		height: 25%;
		background-color: #556ee6;
		border-radius: 100%;
		-webkit-animation: chase-dot-before 2s infinite ease-in-out both;
		animation: chase-dot-before 2s infinite ease-in-out both;
	}

	&:nth-child(1) {
		-webkit-animation-delay: -1.1s;
		animation-delay: -1.1s;
	}

	&:nth-child(1):before {
		-webkit-animation-delay: -1.1s;
		animation-delay: -1.1s;
	}

	&:nth-child(2) {
		-webkit-animation-delay: -1s;
		animation-delay: -1s;
	}

	&:nth-child(2):before {
		-webkit-animation-delay: -1s;
		animation-delay: -1s;
	}

	&:nth-child(3) {
		-webkit-animation-delay: -.9s;
		animation-delay: -.9s;
	}

	&:nth-child(3):before {
		-webkit-animation-delay: -.9s;
		animation-delay: -.9s;
	}

	&:nth-child(4) {
		-webkit-animation-delay: -.8s;
		animation-delay: -.8s;
	}

	&:nth-child(4):before {
		-webkit-animation-delay: -.8s;
		animation-delay: -.8s;
	}

	&:nth-child(5) {
		-webkit-animation-delay: -.7s;
		animation-delay: -.7s;
	}

	&:nth-child(5):before {
		-webkit-animation-delay: -.7s;
		animation-delay: -.7s;
	}

	&:nth-child(6) {
		-webkit-animation-delay: -.6s;
		animation-delay: -.6s;
	}

	&:nth-child(6):before {
		-webkit-animation-delay: -.6s;
		animation-delay: -.6s;
	}
}

[type=email]::-webkit-input-placeholder,
[type=number]::-webkit-input-placeholder,
[type=tel]::-webkit-input-placeholder,
[type=url]::-webkit-input-placeholder {
	text-align: left;
}

[type=email]::-moz-placeholder,
[type=number]::-moz-placeholder,
[type=tel]::-moz-placeholder,
[type=url]::-moz-placeholder {
	text-align: left;
}

[type=email]:-ms-input-placeholder,
[type=number]:-ms-input-placeholder,
[type=tel]:-ms-input-placeholder,
[type=url]:-ms-input-placeholder {
	text-align: left;
}

[type=email]::-ms-input-placeholder,
[type=number]::-ms-input-placeholder,
[type=tel]::-ms-input-placeholder,
[type=url]::-ms-input-placeholder {
	text-align: left;
}

[type=email]::placeholder,
[type=number]::placeholder,
[type=tel]::placeholder,
[type=url]::placeholder {
	text-align: left;
}

.form-check {
	position: relative;
	text-align: left;
}

.form-check-right {
	padding-left: 0;
	display: inline-block;
	padding-right: 1.5em;

	.form-check-input {
		float: right;
		margin-left: 0;
		margin-right: -1.5em;
	}

	.form-check-label {
		display: block;
	}
}

.form-checkbox-outline {
	.form-check-input {
		border-width: 2px;
		background-color: #fff;

		&:active {
			-webkit-filter: none;
			filter: none;
		}

		&:checked {
			background-color: #fff !important;

			&:after {
				position: absolute;
				content: "\f012c";
				font-family: "Material Design Icons";
				top: -4px !important;
				left: 1px;
				font-size: 16px;
				color: #343a40;
			}
		}

		&:checked[type=checkbox] {
			background-image: none;
		}
	}
}

.form-radio-outline {
	.form-check-input {
		background-color: #fff;
		position: relative;

		&:active {
			-webkit-filter: none;
			filter: none;
		}

		&:checked {
			background-color: #fff !important;

			&:after {
				position: absolute;
				content: "";
				top: 3px !important;
				left: 3px;
				width: 5px;
				height: 5px;
				border-radius: 50%;
			}
		}

		&:checked[type=checkbox] {
			background-image: none;
		}
	}
}

.form-check-primary {
	.form-check-input {
		&:checked {
			background-color: #556ee6;
			border-color: #556ee6;
		}
	}
}

.form-radio-primary {
	.form-check-input {
		&:checked {
			border-color: #556ee6;
			background-color: #556ee6;

			&:after {
				background-color: #556ee6;
			}
		}
	}
}

.form-check-secondary {
	.form-check-input {
		&:checked {
			background-color: #74788d;
			border-color: #74788d;
		}
	}
}

.form-radio-secondary {
	.form-check-input {
		&:checked {
			border-color: #74788d;
			background-color: #74788d;

			&:after {
				background-color: #74788d;
			}
		}
	}
}

.form-check-success {
	.form-check-input {
		&:checked {
			background-color: #34c38f;
			border-color: #34c38f;
		}
	}
}

.form-radio-success {
	.form-check-input {
		&:checked {
			border-color: #34c38f;
			background-color: #34c38f;

			&:after {
				background-color: #34c38f;
			}
		}
	}
}

.form-check-info {
	.form-check-input {
		&:checked {
			background-color: #50a5f1;
			border-color: #50a5f1;
		}
	}
}

.form-radio-info {
	.form-check-input {
		&:checked {
			border-color: #50a5f1;
			background-color: #50a5f1;

			&:after {
				background-color: #50a5f1;
			}
		}
	}
}

.form-check-warning {
	.form-check-input {
		&:checked {
			background-color: #f1b44c;
			border-color: #f1b44c;
		}
	}
}

.form-radio-warning {
	.form-check-input {
		&:checked {
			border-color: #f1b44c;
			background-color: #f1b44c;

			&:after {
				background-color: #f1b44c;
			}
		}
	}
}

.form-check-danger {
	.form-check-input {
		&:checked {
			background-color: #f46a6a;
			border-color: #f46a6a;
		}
	}
}

.form-radio-danger {
	.form-check-input {
		&:checked {
			border-color: #f46a6a;
			background-color: #f46a6a;

			&:after {
				background-color: #f46a6a;
			}
		}
	}
}

.form-check-pink {
	.form-check-input {
		&:checked {
			background-color: #e83e8c;
			border-color: #e83e8c;
		}
	}
}

.form-radio-pink {
	.form-check-input {
		&:checked {
			border-color: #e83e8c;
			background-color: #e83e8c;

			&:after {
				background-color: #e83e8c;
			}
		}
	}
}

.form-check-light {
	.form-check-input {
		&:checked {
			background-color: #eff2f7;
			border-color: #eff2f7;
		}
	}
}

.form-radio-light {
	.form-check-input {
		&:checked {
			border-color: #eff2f7;
			background-color: #eff2f7;

			&:after {
				background-color: #eff2f7;
			}
		}
	}
}

.form-check-dark {
	.form-check-input {
		&:checked {
			background-color: #343a40;
			border-color: #343a40;
		}
	}
}

.form-radio-dark {
	.form-check-input {
		&:checked {
			border-color: #343a40;
			background-color: #343a40;

			&:after {
				background-color: #343a40;
			}
		}
	}
}

.form-check,
.form-check-input,
.form-check-label {
	cursor: pointer;
	margin-bottom: 0;
}

.form-switch-md {
	padding-left: 2.5rem;
	min-height: 24px;
	line-height: 24px;

	.form-check-input {
		width: 40px;
		height: 20px;
		left: -.5rem;
		position: relative;
	}

	.form-check-label {
		vertical-align: middle;
	}
}

.form-switch-lg {
	padding-left: 2.75rem;
	min-height: 28px;
	line-height: 28px;

	.form-check-input {
		width: 48px;
		height: 24px;
		left: -.75rem;
		position: relative;
	}
}

.input-group-text {
	margin-bottom: 0;
}

[data-layout-mode=dark] .form-switch .form-check-input,
[data-layout-mode=dark] .form-switch .form-check-input:focus {
	background-image: url("data:image/svg+xml, %3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ced4da'/%3e%3c/svg%3e");
}

.mini-stats-wid {
	.mini-stat-icon {
		overflow: hidden;
		position: relative;

		&::after {
			left: -12px;
			width: 12px;
			-webkit-transition: all .2s;
			transition: all .2s;
		}
	}

	&:hover {
		.mini-stat-icon {
			&::after {
				left: 60px;
			}
		}
	}
}

.mini-stats-wid .mini-stat-icon:after,
.mini-stats-wid .mini-stat-icon:before {
	content: "";
	position: absolute;
	width: 8px;
	height: 54px;
	background-color: rgba(255, 255, 255, .1);
	left: 16px;
	-webkit-transform: rotate(32deg);
	transform: rotate(32deg);
	top: -5px;
	-webkit-transition: all .4s;
	transition: all .4s;
}

.mfp-popup-form {
	max-width: 1140px;
}

.mfp-close {
	color: #343a40 !important;
}

.bs-example-modal {
	position: relative;
	top: auto;
	right: auto;
	bottom: auto;
	left: auto;
	z-index: 1;
	display: block;
}

[dir=rtl] {
	.modal-open {
		padding-left: 0 !important;
	}

	.select2-selection__rendered {
		text-align: end;
	}

	.sp-alpha {
		direction: ltr;
	}

	.sp-original-input-container {
		.sp-add-on {
			border-top-right-radius: 0 !important;
			border-bottom-right-radius: 0 !important;
			border-top-left-radius: 4px !important;
			border-bottom-left-radius: 4px !important;
		}
	}

	input {
		&.spectrum {
			&.with-add-on {
				border: 1px solid var(--bs-input-border-color);
				border-left: 0;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				border-top-right-radius: .25rem;
				border-bottom-right-radius: .25rem;
			}
		}
	}
}

.icon-demo-content {
	text-align: center;
	color: #adb5bd;

	i {
		display: block;
		font-size: 24px;
		margin-bottom: 16px;
		color: #74788d;
		-webkit-transition: all .4s;
		transition: all .4s;
	}

	.col-lg-4 {
		margin-top: 24px;

		&:hover {
			i {
				color: #556ee6;
				-webkit-transform: scale(1.5);
				transform: scale(1.5);
			}
		}
	}
}

.grid-structure {
	.grid-container {
		background-color: var(--bs-gray-100);
		margin-top: 10px;
		font-size: .8rem;
		font-weight: 500;
		padding: 10px 20px;
	}
}

.card-radio {
	background-color: var(--bs-custom-white);
	border: 2px solid var(--bs-border-color);
	border-radius: .25rem;
	padding: 1rem;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	&:hover {
		cursor: pointer;
	}
}

.card-radio-label {
	display: block;
}

.card-radio-input {
	display: none;

	&:checked {
		&+.card-radio {
			border-color: #556ee6 !important;
		}
	}
}

.navs-carousel {
	.owl-nav {
		margin-top: 16px;

		button {
			width: 30px;
			height: 30px;
			line-height: 28px !important;
			font-size: 20px !important;
			border-radius: 50% !important;
			background-color: rgba(85, 110, 230, .25) !important;
			color: #556ee6 !important;
			margin: 4px 8px !important;
		}
	}
}

[data-simplebar] {
	position: relative;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-ms-flex-line-pack: start;
	align-content: flex-start;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.simplebar-wrapper {
	overflow: hidden;
	width: inherit;
	height: inherit;
	max-width: inherit;
	max-height: inherit;
}

.simplebar-mask {
	direction: inherit;
	position: absolute;
	overflow: hidden;
	padding: 0;
	margin: 0;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	width: auto !important;
	height: auto !important;
	z-index: 0;
}

.simplebar-offset {
	direction: inherit !important;
	-webkit-box-sizing: inherit !important;
	box-sizing: inherit !important;
	resize: none !important;
	position: absolute;
	top: 0;
	left: 0 !important;
	bottom: 0;
	right: 0 !important;
	padding: 0;
	margin: 0;
	-webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
	direction: inherit;
	-webkit-box-sizing: border-box !important;
	box-sizing: border-box !important;
	position: relative;
	display: block;
	height: 100%;
	width: auto;
	visibility: visible;
	overflow: auto;
	max-width: 100%;
	max-height: 100%;
	scrollbar-width: none;
	padding: 0 !important;
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
	display: none;
}

.simplebar-content:after,
.simplebar-content:before {
	content: " ";
	display: table;
}

.simplebar-placeholder {
	max-height: 100%;
	max-width: 100%;
	width: 100%;
	pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
	-webkit-box-sizing: inherit !important;
	box-sizing: inherit !important;
	height: 100%;
	width: 100%;
	max-width: 1px;
	position: relative;
	float: left;
	max-height: 1px;
	overflow: hidden;
	z-index: -1;
	padding: 0;
	margin: 0;
	pointer-events: none;
	-webkit-box-flex: inherit;
	-ms-flex-positive: inherit;
	flex-grow: inherit;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
}

.simplebar-height-auto-observer {
	-webkit-box-sizing: inherit;
	box-sizing: inherit;
	display: block;
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	height: 1000%;
	width: 1000%;
	min-height: 1px;
	min-width: 1px;
	overflow: hidden;
	pointer-events: none;
	z-index: -1;
}

.simplebar-track {
	z-index: 1;
	position: absolute;
	right: 0;
	bottom: 0;
	pointer-events: none;
	overflow: hidden;

	&.simplebar-vertical {
		top: 0;
		width: 11px;

		.simplebar-scrollbar {
			&:before {
				top: 2px;
				bottom: 2px;
			}
		}
	}

	&.simplebar-horizontal {
		left: 0;
		height: 11px;

		.simplebar-scrollbar {
			right: auto;
			left: 0;
			top: 2px;
			height: 7px;
			min-height: 0;
			min-width: 10px;
			width: auto;

			&:before {
				height: 100%;
				left: 2px;
				right: 2px;
			}
		}
	}
}

[data-simplebar].simplebar-dragging {
	.simplebar-content {
		pointer-events: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		-webkit-user-select: none;
	}

	.simplebar-track {
		pointer-events: all;
	}
}

.simplebar-scrollbar {
	position: absolute;
	right: 2px;
	width: 4px;
	min-height: 10px;

	&:before {
		position: absolute;
		content: "";
		background: #a2adb7;
		border-radius: 7px;
		left: 0;
		right: 0;
		opacity: 0;
		-webkit-transition: opacity .2s linear;
		transition: opacity .2s linear;
	}

	&.simplebar-visible {
		&:before {
			opacity: .5;
			-webkit-transition: opacity 0s linear;
			transition: opacity 0s linear;
		}
	}
}

[data-simplebar-direction=rtl] {
	.simplebar-track {
		&.simplebar-vertical {
			right: auto;
			left: 0;
		}
	}
}

.hs-dummy-scrollbar-size {
	direction: rtl;
	position: fixed;
	opacity: 0;
	visibility: hidden;
	height: 500px;
	width: 500px;
	overflow-y: hidden;
	overflow-x: scroll;
}

.simplebar-hide-scrollbar {
	position: fixed;
	left: 0;
	visibility: hidden;
	overflow-y: scroll;
	scrollbar-width: none;
}

.custom-scroll {
	height: 100%;
}

.lnb-calendars-item {
	display: inline-block;
	margin-right: 7px;
}

input[type=checkbox].tui-full-calendar-checkbox-round {
	&+span {
		margin-right: 4px;
		margin-left: 0;
	}
}

.select2-container {
	.select2-selection--single {
		background-color: var(--bs-input-bg);
		border: 1px solid var(--bs-input-border-color);
		height: 38px;

		&:focus {
			outline: 0;
		}

		.select2-selection__rendered {
			line-height: 36px;
			padding-left: .75rem;
			color: var(--bs-input-color);
		}

		.select2-selection__arrow {
			height: 34px;
			width: 34px;
			right: 3px;

			b {
				border-color: var(--bs-gray-500) transparent transparent transparent;
				border-width: 6px 6px 0 6px;
			}
		}

		.select2-selection__placeholder {
			color: var(--bs-body-color);
		}
	}

	.select2-selection--multiple {
		min-height: 38px;
		background-color: var(--bs-input-bg);
		border: 1px solid var(--bs-input-border-color) !important;

		.select2-selection__rendered {
			padding: 2px .75rem;
		}

		.select2-search__field {
			border: 0;
			color: var(--bs-input-color);

			&::-webkit-input-placeholder {
				color: var(--bs-input-color);
			}

			&::-moz-placeholder {
				color: var(--bs-input-color);
			}

			&:-ms-input-placeholder {
				color: var(--bs-input-color);
			}

			&::-ms-input-placeholder {
				color: var(--bs-input-color);
			}

			&::placeholder {
				color: var(--bs-input-color);
			}
		}

		.select2-selection__choice {
			background-color: var(--bs-gray-200);
			border: 1px solid var(--bs-gray-300);
			border-radius: 1px;
			padding: 0 7px;
		}
	}
}

.select2-container--open {
	.select2-selection--single {
		.select2-selection__arrow {
			b {
				border-color: transparent transparent var(--bs-gray-500) transparent !important;
				border-width: 0 6px 6px 6px !important;
			}
		}
	}
}

.select2-container--default {
	.select2-search--dropdown {
		padding: 10px;
		background-color: var(--bs-custom-white);

		.select2-search__field {
			border: 1px solid var(--bs-input-border-color);
			background-color: var(--bs-input-bg);
			color: var(--bs-gray-600);
			outline: 0;
		}
	}

	.select2-results__option--highlighted[aria-selected] {
		background-color: #556ee6;
	}

	.select2-results__option[aria-selected=true] {
		background-color: var(--bs-light);
		color: var(--bs-body-color);
	}

	.select2-results__option[aria-selected=true]:hover {
		background-color: #556ee6;
		color: #fff;
	}

	&.select2-container--focus {
		.select2-selection--multiple {
			border-color: var(--bs-gray-400);
		}
	}

	.select2-results__group {
		font-weight: 600;
	}
}

.select2-results__option {
	padding: 6px 12px;
}

.select2-container[dir=rtl] {
	.select2-selection--single {
		.select2-selection__rendered {
			padding-left: .75rem;
		}
	}
}

.select2-dropdown {
	border: 1px solid var(--bs-border-color);
	background-color: var(--bs-custom-white);
	-webkit-box-shadow: 0 .75rem 1.5rem rgba(18, 38, 63, .03);
	box-shadow: 0 .75rem 1.5rem rgba(18, 38, 63, .03);
}

.select2-search {
	input {
		border: 1px solid var(--bs-gray-300);
	}
}

.select2-result-repository__avatar {
	float: left;
	width: 60px;
	margin-right: 10px;

	img {
		width: 100%;
		height: auto;
		border-radius: 2px;
	}
}

.select2-result-repository__statistics {
	margin-top: 7px;
}

.select2-result-repository__forks,
.select2-result-repository__stargazers,
.select2-result-repository__watchers {
	display: inline-block;
	font-size: 11px;
	margin-right: 1em;
	color: var(--bs-gray-500);
}

.select2-result-repository__forks .fa,
.select2-result-repository__stargazers .fa,
.select2-result-repository__watchers .fa {
	margin-right: 4px;
}

.select2-result-repository__forks .fa.fa-flash::before,
.select2-result-repository__stargazers .fa.fa-flash::before,
.select2-result-repository__watchers .fa.fa-flash::before {
	content: "\f0e7";
	font-family: "Font Awesome 5 Free";
}

.select2-results__option--highlighted .select2-result-repository__forks,
.select2-results__option--highlighted .select2-result-repository__stargazers,
.select2-results__option--highlighted .select2-result-repository__watchers {
	color: rgba(255, 255, 255, .8);
}

.select2-result-repository__meta {
	overflow: hidden;
}

.img-flag {
	margin-right: 7px;
	height: 15px;
	width: 18px;
}

input[switch] {
	display: none;

	&+label {
		font-size: 1em;
		line-height: 1;
		width: 56px;
		height: 24px;
		background-color: #ced4da;
		background-image: none;
		border-radius: 2rem;
		padding: .16667rem;
		cursor: pointer;
		display: inline-block;
		text-align: center;
		position: relative;
		font-weight: 500;
		-webkit-transition: all .1s ease-in-out;
		transition: all .1s ease-in-out;

		&:before {
			color: #343a40;
			content: attr(data-off-label);
			display: block;
			font-family: inherit;
			font-weight: 500;
			font-size: 12px;
			line-height: 21px;
			position: absolute;
			right: 1px;
			margin: 3px;
			top: -2px;
			text-align: center;
			min-width: 1.66667rem;
			overflow: hidden;
			-webkit-transition: all .1s ease-in-out;
			transition: all .1s ease-in-out;
		}

		&:after {
			content: "";
			position: absolute;
			left: 3px;
			background-color: #eff2f7;
			-webkit-box-shadow: none;
			box-shadow: none;
			border-radius: 2rem;
			height: 20px;
			width: 20px;
			top: 2px;
			-webkit-transition: all .1s ease-in-out;
			transition: all .1s ease-in-out;
		}
	}
}

input[switch]:checked {
	&+label {
		background-color: #556ee6;
		background-color: #556ee6;

		&:before {
			color: #fff;
			content: attr(data-on-label);
			right: auto;
			left: 3px;
		}

		&:after {
			left: 33px;
			background-color: #eff2f7;
		}
	}
}

input[switch=bool] {
	&+label {
		background-color: #f46a6a;
	}
}

input[switch=bool]+label:before,
input[switch=bool]:checked+label:before,
input[switch=default]:checked+label:before {
	color: #fff;
}

input[switch=bool]:checked {
	&+label {
		background-color: #34c38f;
	}
}

input[switch=default]:checked {
	&+label {
		background-color: #a2a2a2;
	}
}

input[switch=primary]:checked {
	&+label {
		background-color: #556ee6;
	}
}

input[switch=success]:checked {
	&+label {
		background-color: #34c38f;
	}
}

input[switch=info]:checked {
	&+label {
		background-color: #50a5f1;
	}
}

input[switch=warning]:checked {
	&+label {
		background-color: #f1b44c;
	}
}

input[switch=danger]:checked {
	&+label {
		background-color: #f46a6a;
	}
}

input[switch=dark]:checked {
	&+label {
		background-color: #343a40;

		&:before {
			color: #eff2f7;
		}
	}
}

.square-switch {
	margin-right: 7px;
}

.square-switch input[switch]+label,
.square-switch input[switch]+label:after {
	border-radius: 4px;
}

.sp-container {
	background-color: #fff;

	button {
		padding: .25rem .5rem;
		font-size: .71094rem;
		border-radius: .2rem;
		font-weight: 400;
		color: #343a40;

		&.sp-palette-toggle {
			background-color: #eff2f7;
		}

		&.sp-choose {
			background-color: #34c38f;
			margin-left: 5px;
			margin-right: 0;
		}
	}
}

.sp-palette-container {
	border-right: 1px solid #eff2f7;
}

.sp-input {
	background-color: var(--bs-custom-white);
	border-color: var(--bs-input-border-color) !important;
	color: var(--bs-gray-700);

	&:focus {
		outline: 0;
	}
}

.bootstrap-timepicker-widget {
	left: 0 !important;
	right: auto !important;

	table {
		td {
			a {
				color: var(--bs-gray-700);

				&:hover {
					background-color: transparent;
					border-color: transparent;
					border-radius: 4px;
					color: #556ee6;
					text-decoration: none;
				}
			}

			input {
				width: 32px;
				height: 32px;
				border: 0;
				color: var(--bs-body-color);
				border: 1px solid var(--bs-border-color);
				background-color: var(--bs-custom-white);
			}
		}
	}

	&.dropdown-menu {
		&:after {
			border-bottom-color: var(--bs-gray-200);
		}
	}

	&.timepicker-orient-bottom {
		&:after {
			border-top-color: var(--bs-gray-200);
		}
	}

	&.timepicker-orient-left {
		&:before {
			left: 6px;
			right: auto;
		}

		&::after {
			left: 7px;
			right: auto;
		}
	}
}

.timepicker-orient-top {
	top: calc(1.5em + .94rem + 2px) !important;
}

.timepicker-orient-bottom {
	top: auto !important;
	bottom: calc(1.5em + .94rem + 2px) !important;
}

.datepicker {
	border: 1px solid var(--bs-border-color);
	padding: 8px;
	color: var(--bs-text-muted);

	table {
		tr {
			th {
				font-weight: 500;
			}
		}
	}
}

.datepicker table tr td .active.disabled,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td.active,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active:hover,
.datepicker table tr td.selected,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected:hover,
.datepicker table tr td.today,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today:hover {
	background-color: #556ee6 !important;
	background-image: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	color: #fff !important;
}

.datepicker table tr td span.focused,
.datepicker table tr td span:hover,
.datepicker table tr td.day.focused,
.datepicker table tr td.day:hover {
	background: var(--bs-light);
}

.datepicker table tr td span.new,
.datepicker table tr td span.old,
.datepicker table tr td.new,
.datepicker table tr td.old {
	color: var(--bs-gray-500);
	opacity: .6;
}

.datepicker table tr td.range,
.datepicker table tr td.range.disabled,
.datepicker table tr td.range.disabled:hover,
.datepicker table tr td.range:hover {
	background-color: var(--bs-gray-300);
}

.table-condensed>tbody>tr>td,
.table-condensed>thead>tr>th {
	padding: 7px;
}

.bootstrap-datepicker-inline {
	.datepicker-inline {
		width: auto !important;
		display: inline-block;
	}
}

.datepicker-container {
	border: 1px solid #eff2f7;
	-webkit-box-shadow: none;
	box-shadow: none;
	background-color: #fff;

	&.datepicker-inline {
		width: 212px;
	}
}

.datepicker .datepicker-switch:hover,
.datepicker .next:hover,
.datepicker .prev:hover,
.datepicker tfoot tr th:hover {
	background: var(--bs-light);
}

.datepicker-panel {
	&>ul {
		&>li {
			background-color: #fff;
			border-radius: 4px;
		}
	}

	&>ul[data-view=week] {
		&>li {
			font-weight: 500;
		}
	}
}

.datepicker-panel>ul>li.picked,
.datepicker-panel>ul>li.picked:hover {
	background-color: rgba(85, 110, 230, .25);
	color: #556ee6;
}

.datepicker-panel>ul>li.highlighted,
.datepicker-panel>ul>li.highlighted:hover,
.datepicker-panel>ul>li:hover {
	background-color: #556ee6;
	color: #fff;
}

.datepicker-panel>ul>li.muted,
.datepicker-panel>ul>li.muted:hover {
	color: var(--bs-gray-500);
	opacity: .6;
}

.datepicker-panel>ul[data-view=week]>li,
.datepicker-panel>ul[data-view=week]>li:hover {
	background-color: #fff;
}

.bootstrap-touchspin.input-group>.input-group-prepend>.btn,
.bootstrap-touchspin.input-group>.input-group-prepend>.input-group-text {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.bootstrap-touchspin.input-group>.input-group-append>.btn,
.bootstrap-touchspin.input-group>.input-group-append>.input-group-text {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.bootstrap-touchspin {
	.input-group-btn-vertical {
		right: 0;

		.btn {
			right: 0 !important;
			left: 100% !important;
		}

		.bootstrap-touchspin-up {
			border-top-right-radius: 4px !important;
			border-bottom-right-radius: 0 !important;
			border-top-left-radius: 0 !important;
			border-bottom-left-radius: 0 !important;
		}

		.bootstrap-touchspin-down {
			border-top-right-radius: 0 !important;
			border-bottom-right-radius: 4px !important;
			border-top-left-radius: 0 !important;
			border-bottom-left-radius: 0 !important;
		}
	}
}

.editable-input {
	.form-control {
		display: inline-block;
	}
}

.editable-buttons {
	margin-left: 7px;

	.editable-cancel {
		margin-left: 7px;
	}
}

.dropzone {
	min-height: 230px;
	border: 2px dashed var(--bs-border-color);
	background: var(--bs-custom-white);
	border-radius: 6px;

	.dz-message {
		font-size: 24px;
		width: 100%;
	}
}

.form-wizard-wrapper {
	label {
		font-size: 14px;
		text-align: right;
	}
}

#vertical-menu-btn {
	width: 55px;
}

.card {
	margin-bottom: 24px;
	-webkit-box-shadow: unset;
	box-shadow: unset;
	margin-top: 5px;
}

@media (max-width:992px) {
	.navbar-brand-box {
		width: auto;
	}

	.logo span.logo-lg {
		display: none;
	}

	.logo span.logo-sm {
		display: inline-block;
	}
}

@media (max-width:600px) {
	.navbar-header .dropdown {
		position: static;
	}

	.navbar-header .dropdown .dropdown-menu {
		left: 10px !important;
		right: 10px !important;
	}
}

@media (max-width:380px) {
	.navbar-brand-box {
		display: none;
	}
}

@media (max-width:992px) {
	body[data-layout=horizontal] .page-content {
		margin-top: 15px;
	}
}

@media (max-width:991.98px) {
	.footer {
		left: 0;
	}
}

@media (max-width:991.98px) {
	.vertical-collpsed .footer {
		left: 0;
	}
}

@media (max-width:767.98px) {
	.right-bar {
		overflow: auto;
	}

	.right-bar .slimscroll-menu {
		height: auto !important;
	}
}

@media (max-width:992px) {
	.vertical-menu {
		display: none;
	}

	.main-content {
		margin-left: 0 !important;
	}

	body.sidebar-enable .vertical-menu {
		display: block;
	}
}

@media (max-width:991.98px) {
	body[data-sidebar-size=small] .footer {
		left: 0;
	}
}

@media (min-width:1200px) {

	body[data-layout=horizontal] .container-fluid,
	body[data-layout=horizontal] .navbar-header {
		max-width: 85%
	}
}

@media (min-width:992px) {
	.topnav .navbar-nav .nav-item:first-of-type .nav-link {
		padding-left: 0;
	}

	.topnav .dropdown-item {
		padding: .5rem 1.5rem;
		min-width: 180px;
	}

	.topnav .dropdown.mega-dropdown .mega-dropdown-menu {
		left: 0;
		right: auto;
	}

	.topnav .dropdown .dropdown-menu {
		margin-top: 0;
		border-radius: 0 0 .25rem .25rem;
	}

	.topnav .dropdown .dropdown-menu .arrow-down::after {
		right: 15px;
		-webkit-transform: rotate(-135deg) translateY(-50%);
		transform: rotate(-135deg) translateY(-50%);
		position: absolute;
	}

	.topnav .dropdown .dropdown-menu .dropdown .dropdown-menu {
		position: absolute;
		top: 0 !important;
		left: 100%;
		display: none;
	}

	.topnav .dropdown:hover>.dropdown-menu {
		display: block;
	}

	.topnav .dropdown:hover>.dropdown-menu>.dropdown:hover>.dropdown-menu {
		display: block;
	}

	.navbar-toggle {
		display: none;
	}
}

@media (max-width:1199.98px) {
	.topnav-menu .navbar-nav li:last-of-type .dropdown .dropdown-menu {
		right: 100%;
		left: auto;
	}
}

@media (max-width:991.98px) {
	.navbar-brand-box .logo-dark {
		display: block;
	}

	.navbar-brand-box .logo-dark span.logo-sm {
		display: block;
	}

	.navbar-brand-box .logo-light {
		display: none;
	}

	.topnav {
		max-height: 360px;
		overflow-y: auto;
		padding: 0;
	}

	.topnav .navbar-nav .nav-link {
		padding: .75rem 1.1rem;
	}

	.topnav .dropdown .dropdown-menu {
		background-color: transparent;
		border: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		padding-left: 15px;
	}

	.topnav .dropdown .dropdown-menu.dropdown-mega-menu-xl {
		width: auto;
	}

	.topnav .dropdown .dropdown-menu.dropdown-mega-menu-xl .row {
		margin: 0;
	}

	.topnav .dropdown .dropdown-item {
		position: relative;
		background-color: transparent;
	}

	.topnav .dropdown .dropdown-item.active,
	.topnav .dropdown .dropdown-item:active {
		color: #556ee6;
	}

	.topnav .arrow-down::after {
		right: 15px;
		position: absolute;
	}
}

@media (min-width:992px) {
	body[data-layout=horizontal][data-topbar=light] .navbar-brand-box .logo-dark {
		display: block;
	}

	body[data-layout=horizontal][data-topbar=light] .navbar-brand-box .logo-light {
		display: none;
	}

	body[data-layout=horizontal][data-topbar=light] .topnav {
		background-color: #556ee6;
	}

	body[data-layout=horizontal][data-topbar=light] .topnav .navbar-nav .nav-link {
		color: rgba(255, 255, 255, .6);
	}

	body[data-layout=horizontal][data-topbar=light] .topnav .navbar-nav .nav-link:focus,
	body[data-layout=horizontal][data-topbar=light] .topnav .navbar-nav .nav-link:hover {
		color: rgba(255, 255, 255, .9);
	}

	body[data-layout=horizontal][data-topbar=light] .topnav .navbar-nav>.dropdown.active>a {
		color: rgba(255, 255, 255, .9) !important;
	}
}

@media (min-width:992px) {
	body[data-layout=horizontal][data-topbar=colored] .topnav {
		background-color: #556ee6;
	}

	body[data-layout=horizontal][data-topbar=colored] .topnav .navbar-nav .nav-link {
		color: rgba(255, 255, 255, .6);
	}

	body[data-layout=horizontal][data-topbar=colored] .topnav .navbar-nav .nav-link:focus,
	body[data-layout=horizontal][data-topbar=colored] .topnav .navbar-nav .nav-link:hover {
		color: rgba(255, 255, 255, .9);
	}

	body[data-layout=horizontal][data-topbar=colored] .topnav .navbar-nav>.dropdown.active>a {
		color: rgba(255, 255, 255, .9) !important;
	}
}

@media (min-width:992px) {

	body[data-layout-scrollable=true] #page-topbar,
	body[data-layout-scrollable=true] .vertical-menu {
		position: absolute;
	}
}

@media (min-width:992px) {

	body[data-layout-scrollable=true][data-layout=horizontal] #page-topbar,
	body[data-layout-scrollable=true][data-layout=horizontal] .topnav {
		position: absolute;
	}
}

@media print {

	.footer,
	.navbar-header,
	.page-title-box,
	.right-bar,
	.vertical-menu {
		display: none !important;
	}

	.card-body,
	.main-content,
	.page-content,
	.right-bar,
	body {
		padding: 0;
		margin: 0;
	}

	.card {
		border: 0;
	}
}

@-webkit-keyframes spinner-chase {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes spinner-chase {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-webkit-keyframes chase-dot {

	100%,
	80% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes chase-dot {

	100%,
	80% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-webkit-keyframes chase-dot-before {
	50% {
		-webkit-transform: scale(.4);
		transform: scale(.4);
	}

	0%,
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes chase-dot-before {
	50% {
		-webkit-transform: scale(.4);
		transform: scale(.4);
	}

	0%,
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

.cus-icon {
	font-size: 22px;
	vertical-align: sub;
}