.toggleSwitch {
    display: inline-block;
    height: 18px;
    position: relative;
    overflow: visible;
    padding: 0;
    cursor: pointer;
    width: 80px;
    background-color: #fafafa;
    border: 1px solid #007ACC;
    border-radius: 14px;
    height: 34px;
    margin-top: 0px;
    margin-bottom: -8px;
}
.toggleSwitch * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.toggleSwitch label,
.toggleSwitch > span {
    line-height: 20px;
    height: 20px;
    vertical-align: middle;
}
.toggleSwitch input:focus ~ a,
.toggleSwitch input:focus + label {
    outline: none;
}
.toggleSwitch label {
    position: relative;
    z-index: 3;
    display: block;
    width: 100%;
}
.toggleSwitch input {
    position: absolute;
    opacity: 0;
    z-index: 5;
}
.toggleSwitch > span {
    position: absolute;
    left: 0;
    width: calc(100% - 0px);
    /* margin: 0; */
    text-align: left;
    white-space: nowrap;
    /* margin: 0 3px; */
}

.toggleSwitch > span span {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    display: block;
    width: 50%;
    margin-left: 50px;
    text-align: left;
    font-size: 0.9em;
    width: auto;
    left: 0;
    top: -1px;
    opacity: 1;
    width:40%;
    text-align: center;
  line-height:34px;
}
.toggleSwitch a {
    position: absolute;
    right: 50%;
    z-index: 4;
    display: block;
    top: 0px;
    bottom: 0px;
    padding: 0;
    left: 0px;
    width: 50%;
    background: transparent;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}
.toggleSwitch > span span:first-of-type {
    color: #FFF;
    opacity: 1;
    left: 0;
    margin: 0;
    width: 50%;
    background-color:#007ACC;
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;

   
}
.toggleSwitch > span span:last-of-type {
    left:auto;
    right:0;
    color: #999;
    margin: 0;
    width: 50%;
    background-color:transparent;
}
.toggleSwitch > span:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: -2px;
    /* background-color: #fafafa;
    border: 1px solid #ccc; */
    border-radius: 30px;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}
.toggleSwitch input:checked ~ a {
    left: calc(50% - 0px);
}

.toggleSwitch input:checked ~ span span:first-of-type {
    left:0;
    color:#999;
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
    background-color: transparent;
}
.toggleSwitch input:checked ~ span span:last-of-type {
    /* opacity: 1;s
    color: #fff;	 */
    color:#FFF;
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
    background-color: #007ACC;
}
/* Switch Sizes */
.toggleSwitch.large {
    width: 60px;
    height: 27px;
}
.toggleSwitch.large a {
    width: 27px;
}
.toggleSwitch.large > span {
    height: 29px;
    line-height: 28px;
}
.toggleSwitch.large input:checked ~ a {
    left: 41px;
}
.toggleSwitch.large > span span {
    font-size: 1.1em;
}
.toggleSwitch.large > span span:first-of-type {
    left: 50%;
}
.toggleSwitch.xlarge {
    width: 80px;
    height: 36px;
}
.toggleSwitch.xlarge a {
    width: 36px;
}
.toggleSwitch.xlarge > span {
    height: 38px;
    line-height: 37px;
}
.toggleSwitch.xlarge input:checked ~ a {
    left: 52px;
}
.toggleSwitch.xlarge > span span {
    font-size: 1.4em;
}
.toggleSwitch.xlarge > span span:first-of-type {
    left: 50%;
}

  /*  End Toggle Switch  */
