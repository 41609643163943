body{
    overflow: auto;
}
.doodle-data2 {
    position: fixed;
    right: 0px;
    top: 0;
    z-index: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
}
.header-section {
    padding: 0px 0px 0px 0px;
    position: sticky;
    top: 0;
    z-index: 99;    
    .cst-fluid
    {
        padding: 0px 89px!important;
        @media(max-width:1024px)
        {
            padding: 0px 18px!important;
        }
    }
.main-header-form-fill
{
    .ct-header
    {
        background:#F7F8F9;
    }
    .cst-header
    {
        padding: 0px 9px;
    }
    h1 {
        color: #007acc;
        margin-bottom: 0px;
        margin-top: 7px;
    }
    h4 {
        font-weight: 400;
        font-size: 22px;
        line-height: 32px;
        color: #2d2d2d;
    }
    .log-out {
        text-align: end;
        z-index: 9999;
        margin-top: 25px;

        a{
            font-weight: 500;
            font-size: 18px;
            // line-height: 29px;
            color: #007acc;
            text-align: right;
            padding: 6px 20px;
            border-radius: 10px;
            @media(max-width:1024px)
            {
                padding: 6px 12px;
                display: inline-block;
            }
            &:hover{
            background: #007ACC;
            color:#fff;
            padding: 6px 20px;
            @media(max-width:1024px)
            {
                padding: 6px 12px;
            }
        }
        }
    }
}
    
}

.frm-maincontent {
    padding: 0px 0px 30px 0px;
    background-repeat: no-repeat;
    background-size: contain;
    .doodle-data {
        position: fixed;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        background-attachment: fixed;
       
    }
    .form-main {
        background: #ffffff;
        /* Righ Pane Card Shadow */

        box-shadow: 14px 10px 7px rgba(0, 0, 0, 0.01), -3px 6px 6px rgba(0, 0, 0, 0.03), 3px 2px 4px rgba(0, 0, 0, 0.04), 1px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05);;
        border-radius: 0px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        padding: 0;
        overflow: scroll;
        border: black;

        .form-maintab-content {
            .tab-content {
                min-height: 500px;
            }
            .form-start {
                padding: 20px 30px 30px;
                border: 1px solid transparent;


            }
        }
    .footer-btn
    {
        .cst-gry {
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            color: #2D2D2D;
            padding: 6px 20px;
            &:hover{
                background-color: #ECECEC;
                color:#007ACC;
                border-radius: 10px;
            }
        }
    }
    }
    .i-href {
        font-size: 14px;
        color: #007acc;
        padding: 6px;
        margin-left: 3px;
        margin-top: -10px;
        margin-bottom: 20px;
        width:100%;
        &:hover {
            background: transparent;
        }
    }
    .pr-info {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        color: #2d2d2d;
        
        .cs-lab {
            font-size: 14px;
            color: #a3a3a3;
        }
    }
    .bl-dt {
        margin-right: 10px;
        border-radius: 10px;
    }
}
.dt0line {
    &::after {
        content: "";
        position: absolute;
        width: 11%;
        height: 1px;
        background-color: grey;
        top: 25px;
        // left: 9.5%;
        /* z-index: -999; */
        transition: all ease-in-out 0.3s;
    }
}
.rd {
    color: #D70015;
    font-weight: 600;
}

.form-menu {
    background: #fbfdff;
    box-shadow: 14px 10px 7px rgba(0, 0, 0, 0.01), 8px 6px 6px rgba(0, 0, 0, 0.03), 3px 2px 4px rgba(0, 0, 0, 0.04),
        1px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05);
    border-radius: 15px 15px 0px 0px;
    padding: 0px 5px;
    vertical-align: middle;
    margin: 0px;
    width: 100%;
    position: sticky;
    top: 0;
    .timeline {
        position: relative;
        margin-top: 10px;
        .nav-item {
            width: 16.6%;
            text-align: center;
            cursor: pointer;
            .nav-link {
                display: inline-grid;
                border: 0px;
                .rnd-data {
                    border-radius: 50%;
                    width: 34px;
                    height: 34px;
                    padding: 1px 5px;
                    border: 1px solid #a3a3a3;
                    color: #a3a3a3;
                    text-align: center;
                    margin: auto;
                    position: relative;
                    cursor: pointer;
                }

                span {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 29px;
                    color: #a3a3a3;
                }
            }
        }
    }
    .nav-pills .nav-link.active,
    .nav-pills .show > .nav-link {
        display: inline-grid;
        border: 0px;
        color: #007acc;
        background: transparent;
        cursor: pointer;
        .rnd-data {
            color: #fff;
            border: 1px solid #007acc;
            background-color: #007acc;
        }
        span {
            color: #007acc;
            cursor: pointer;
        }
    }
}

.document-data {
    padding: 30px;
    .rit-border {
        border-right: 0.5px solid #D9D9D9;
        padding-left: 0px;
        padding-right: 0px;
    
        .nav-menu-data
        {
            padding: 15px;
            padding-top: 10px;
            @media(max-width:1024px)
            {
                padding-left: 0px;
            }
           .navbar
            {
                .nav-link{
                    @media(max-width:1024px)
                    {
                        font-size: 12px!important;
                    }
                }
            }       
                .nav-pills 
            {
                display: block;
                .nav-link
            {
                padding-left: 0px;
                padding-bottom: 3px;
                
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 14px;
                padding-top: 10px;
                padding-right: 4px;
                color: #A3A3A3;
                

            }
            }
            .nav-pills .nav-link.active, .nav-pills .show > .nav-link 
            {
                color: #007acc;
            }
        }
    }
    .finances-data
    {
        padding: 10px;
        h4{
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 35px;
            color: #2D2D2D;
        }
        .tab-fin
        {
            padding-bottom: 30px;
            .fin-table
        {
            border-collapse: collapse;
                border-top-left-radius: 1em;
                border-top-right-radius: 1em;
                overflow: hidden;
                width:100%;
            thead {
                background: #F7F8F9;      
                border-top-left-radius: 15px;   
                border-top-right-radius: 15px; 
                       
                tr{
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 23px;
                    color: #9E9E9E;
                }
            }
            .fin-tb-body
            {
                border: 0.5px solid #D9D9D9;
                border-radius: 6px;
                
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 23px;

                color: #2D2D2D;

                .wt-preline
                {
                    white-space: pre-line;
                }
                td{
                    span{
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 17px;
                        text-decoration-line: underline;
                        color: #007ACC;
                        white-space: initial;
                        padding-left: 3px;
                        a {
                            svg
                            {
                                margin-right: 3px;
                            }
                        }
                    }
                }
            }
        }
        .tab-cl
        {
            margin-top: 20px;
            thead
            {
                background: #FBFDFF!important;
                border-radius: 15px 15px 0px 0px;
            }
            .clr-grey
            {
                background: #F7F8F9;
                .cr-gry
                {
                    
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 23px;

                    color: #A3A3A3;
                }
            }
            
        }
        }
        
    }
    .action-btn
    {
        display: flex;
        text-align: center;
        justify-content: center;
        button{
            background: transparent;
            border: 0px;
            svg{
                color: #A3A3A3;
                font-size: 20px;
            }
        }
    }
    .footer-btn
    {
        padding-top: 20px;
        border-top: 0.5px solid #D9D9D9;
        display: block;
        p{
            font-weight: 400;
            font-size: 14px;
            line-height: 26px;
            /* identical to box height */


            color: #A3A3A3;
            svg
            {
                vertical-align: sub;
    font-size: 20px;
            }
        }
        .sv-btn
        {
            display: flex;
    justify-content: end;
        }
        .cst-gry{
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            /* identical to box height */


            color: #2D2D2D;

        }
    }
}
.rnd-data
{
    &::after{
        content: "";
        height: 0.002rem;
        width: 116px;
        background: black;
        top: 15px;
        bottom: 0;
        position: absolute;
        right: auto;
        left: 100%;
        margin-left: 10px;
        border: 0.0002rem solid #D9D9D9;
        @media(max-width:1290px)
        {
            width:80px;
        }
    }
}
.form-menu
{
    .timeline
    {
        li:last-child{
            .nav-link 
            {
                span{
                    &::after{
                     content: "";
                     width: 0px;
                     @media(max-width:1024px)
                     {
                        width: 0px !important;
                     }
                    }
                }
            }

        }
    }
}


@media(max-width:1024px)
{
    .doodle-data2
    {
        display: none;
    }
    .frm-maincontent
    {
        .doodle-data
        {
            display: none;
        }
        
    }
    .rnd-data
        {
            &::after {
                width: 52px!important;
                background: unset;
            }
        }
}
@media(max-width:1200px)
{
    .rnd-data
    {
        &::after {
            width: 94px;
            background: unset;
        }
    }
    .doodle-data2
    {
      top:0;
      z-index: 0;
    }
    .frm-maincontent
    {
        .doodle-data
        {
            height:200px
        }
        
    }
}
.form-start{
    .footer-btn
    {
        padding-top: 20px;
        border-top: 0.5px solid #D9D9D9;
        display: block;
        p{
            font-weight: 400;
            font-size: 14px;
            line-height: 26px;
            /* identical to box height */


            color: #A3A3A3;
            svg
            {
                vertical-align: sub;
    font-size: 20px;
            }
        }
        .sv-btn
        {
            display: flex;
    justify-content: end;
        }
        .cst-gry{
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            /* identical to box height */


            color: #2D2D2D;

        }
    }
}
.rd-clr{
    color: #d70015 !important;;
}
.thks-data
{
   
	width:600px;
	margin: 0 auto;
	@media(max-width:600px)
	{
			width: unset
	}

    img{
        padding: 30px 18px;
        margin: auto;
        display: block;
        border: 0.4px solid #a3a3a3;
        border-radius: 50%;
        @media(max-width:600px)
        {
            width: 112px;
            padding: 22px 18px;
        }
        
    }
    h1{
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 48px;
        text-align: center;
        color:#007ACC;
        @media(max-width:600px)
        {	
            font-size: 18px;
            line-height: 30px;
        }
        
    }
    h5{
        font-style: normal;
        font-weight: 400;
        font-size: 23px;
        line-height: 48px;
        /* identical to box height */
        
        text-align: center;
        
        color: #A3A3A3;
        padding-top: 20px;
        @media(max-width:600px)
        {	
            font-size: 14px;
            line-height: 30px;
        }
    }
}
.mt-top-30
{
    margin-top: 20px;
}
.pt-top{
    padding-top: 10px;
}
.radio-crtpt
{
    .form-radio-dt
    {
        label
        {
            font-weight: 400;
        }
    }
}
.facility-ownership
{
    .i-href-dt
    {
        font-size: 14px;
        color: #292929;
        padding: 6px;
        margin-left: 3px;
         svg {
            font-size: 19px;
            color: #007ACC;
            padding-right: 0px;
            vertical-align: sub;
            margin-right: 5px;
        }
        &:hover{
        background: #ECECEC;
        color:#007ACC;
        }
    }
}
.modal-footer
{
    .btn-cst-blue {
        background-color: #007ACC;
        padding: 6px 10px;
        color: #fff;
        font-size: 14px;
        font-weight: 300;
        border: unset;
        border-radius: 8px;
        svg{
            font-size: 14px;
        }
    }
    .btn-cst-grey
    {
        padding: 6px 10px;
        color: #292929;
        font-size: 14px;
        font-weight: 500;
        border: unset;
        border-radius: 8px;
        margin-right: 10px;
        svg{
            font-size: 14px;
            margin-right: 5px;
            color: #292929;
        }
    }
}
.modal-header
{
    .cls
    {
        cursor: pointer;
        svg{
            color:#292929;
        }
    }
}

@media(max-width:600px)
{
    .rnd-data
    {
        &::after {
            display: none;
        }
    }
.form-menu
{
    padding: 0px 2px!important;
    .timeline {
        .nav-item {
            
                width: 16%;
                text-align: center;
            .nav-link {
                display: inline-grid;
                border: 0px;
                .rnd-data {
                    width: 24px !important;
                    height: 24px!important;
                    font-size: 10px!important;
                }

                span {
                    
                    font-size: 8px!important;
                    line-height: 25px!important;
                    
                }
            }
        }
    }

}
.header-section 
{
    
    h1 {
        color: #007acc;
        margin-bottom: 0px;
        font-size: 18px;
    }
    h4 {
        font-weight: 400;
        font-size: 16px;
        line-height: 32px;
        color: #2d2d2d;
    }
    .ct-header
    {
        display: flex;
        .ct-left
        {
            width: 70%;
        }
        .ct-right
        {
            width: 30%;
            .log-out {
                font-weight: 500;
                font-size: 16px !important;
                line-height: 19px!important;
                color: #007acc ;
                padding-top: 20px!important;      
                text-align: right;
            }
        }
    }
}
.header-section 
    {
        h1 {
            font-size: 14px!important;
        }
       h4 {
        font-size: 13px!important;
       }
       .ct-header
        {
            .ct-left {
                width: 60%;
            }
            .ct-right
            {
                width: 40%;
                .log-out {
                  display: block;
                  padding-bottom: 20px;
                  a{
                    font-size: 10px;
                  }
                }
            }
        }
    }
}
.nav-menu-data
{
    position: fixed;
    .navbar
    {
        ul{
            list-style: none;
            padding-left: 0px;
        }
        
    }
    @media(max-width:600px)
    {
        position: relative;
    }
}
a.disabled 
{
    pointer-events: none;
    cursor: default;
}
.create-insurance-form {
    padding-top: 10px;
    .date-icon, .date-iconexp, .date-agreiconexp, .date-iconagrestart,.date-curdate,.date-libagreiconexp, .date-iconlibagrestart, .date-iconcoverexpdate ,.date-iconcoverstartdate,.date-iconmedlibstartdate, .date-iconmedlibexpdate,.date-iconmedlibagrestartdate,.date-iconmedlibagreexpdate
        {
            position: absolute;
            top: 16px;
            right: 10px;
            /* pointer-events: none; */
            cursor: pointer;
            color: #aaa;
            z-index: 2;
        }
        .contain-insurance
        {
            padding-bottom: 0px;
            border-bottom: 0px solid #a3a3a3;
            p{
                
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 23px;

                color: #A3A3A3;
                @media (max-width:1024px) {
                    font-size: 13px;
                }
            }
            .pd-right-45
            {
                @media (max-width:1024px) {
                   padding-right: 30px!important;
                }
            }
            .sort-cate {
                
                    @media (max-width:1024px) {
                        font-size: 13px!important;
                    }
                }
                .cat-label
                {
                    @media (max-width:1024px) {
                        font-size: 13px!important;
                    }
                }
            .inputformdata
            {
                &::placeholder
                {
                    color:#a3a3a3;
                    font-weight: 400;
                    @media(max-width:600px)
                    {
                        font-size: 12px;
                    }
                }
            }
        }
        
}
.phy-cls
{
    .sort-cate {
                
        @media (max-width:1024px) {
            font-size: 13px!important;
        }
    }
    .cat-label
    {
        @media (max-width:1024px) {
            font-size: 13px!important;
        }
    } 
}

.application-data
        {
            p{
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 33px;
                color: #A3A3A3;
                @media(max-width:600px)
                {
                    text-align: left;
                    font-size: 13px;
                }
            }
        }
        .date-curdate
        {
            position: absolute;
            top: 16px;
            right: 10px;
            /* pointer-events: none; */
            cursor: pointer;
            color: #aaa;
            z-index: 0;
        }
        .esignature {
            position: relative;
            bottom: 0;
            background: #fff;
            width: auto;
            padding-top: 10px;
            padding-bottom: 5px;
            margin-left: 0px;
            .esign {
                position: absolute;
                left: 1.5%;
                padding: 10px;
                color: #A3A3A3;
                
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
            }
            .alert {
                position: absolute;
                left: 1.5%;
                padding: 10px;
                color: #D70015;
                
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
            }
            .cts-btn {
                position: absolute;
                right:0px;
                background-color: transparent;
                border: 0px;
                padding: 10px;
                color: #D64D55;
            }
            #sig-canvas {
                border: 1px solid #a3a3a3;
                border-radius: 5px;
                /* cursor: crosshair; */
                width: 100%;
            }
            
        }
    .ui-datepicker-div
    {
        z-index: 2!important;
    }

    .pil-insur
    {
      .ft-14
      {
        font-size: 14px;
      }
    }
    .tab-content>.active
    {
        outline: none!important;
    }
    .ft-16
    {
        
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 23px;
        color: #A3A3A3;
    }
    .tab-fin 
    {
        .fin-table {
            border-collapse: collapse;
            border-top-left-radius: 1em;
            border-top-right-radius: 1em;
            overflow: hidden;
            .fin-tb-body {
                border: 0.5px solid #D9D9D9;
                border-radius: 6px;
            }
        }
    }
    .ct-data
    {
        input{
            border-top:0px;
            border-left:0px;
            border-right: 0px;
            border-bottom: 1.2px solid #a3a3a3;
            outline: none;
            // box-shadow: none;
            font-weight: 400;
            font-size: 18px;
            // line-height: 33px;
            color: #A3A3A3;
            height: 32px;
            &:focus
            {
                outline: none;
            box-shadow: none;
            }
            @media(max-width:600px)
                {
                    width:60%;
                }
        }
    }
    .add-field
    {
        padding-top: 20px;
    }
    .frm-maincontent
    {
        .pr-blue
        {
            span
            {
                
                font-style: normal;
                font-weight: 500;
                font-size: 32px;
                line-height: 46px;        
                color: #007ACC;
            }
        }
    }
.btn-disable
{
    opacity: 0.5;
    cursor: none;
}

.cnd-fluid
    {
        padding: 0px 100px;
        // height:80vh;
        // overflow:scroll;
        // padding-bottom:60px;
        // margin-bottom: 30px;
        @media(max-width:1024px)
        {
            padding: 0px 18px!important;
            // padding-bottom:60px!important;
        }
    }
.facility-overview
{
    position: relative;
    .ft-pr-cen
    {
        justify-content: center;
    }
    h1{
        color:#000;
        margin-bottom: 0.1rem;
        font-size: 28px;
        font-weight: 600;
    }
    .sub-header
    {
        color: #000;
        font-size: 20px;
        margin-bottom: 0px;
        margin-bottom: 0.1rem;
    }

    .pad-rt-10
    {
        padding-right: 20px;
        margin-bottom: 0px;
        color: #6C757D;
        font-size: 10px;
    }
    .min-hg
    {
        min-height: 450px;

        .fc-pr{
            display: grid;
            align-items: center;
            padding-top: 160px;
            h1{
                color: #007ACC;
                font-size: 50px;
                letter-spacing: 0.1rem;
                z-index: 9;
            }
            
        }
        .fc-btm
        {
            padding-top: 30px;
            h6{
                font-weight: bold;
            }
        }
    }
    
    .dodle-1
    {
        position: absolute;
        right:0px;
        bottom:50px;
        width: 370px;
    }
    .ft-right
    {
        p,h6{
            text-align: right;
        }
    }
}
.sub-data
{
	p{
		margin-bottom: 0px;
        color:#A3A3A3;
	}
    h1{
        color:#000;
    }
}
.pf-header{
    box-shadow: 0px 0px 10px rgba(145, 145, 145, 0.01), 0px 0px 9px rgba(145, 145, 145, 0.04), 0px 0px 8px rgba(145, 145, 145, 0.14), 0px 0px 6px rgba(145, 145, 145, 0.23), 0px 0px 3px rgba(145, 145, 145, 0.27), 0px 0px 0px rgba(145, 145, 145, 0.28);
}
.fc-btm
{
    margin-bottom: 20px;
    p{
        margin-bottom: 0px;
        color:#000;
    }
}

.pdf-footer
{
    .ft-container
    {
        border-top: 1px solid #000000;
        position: relative;
        .hd-pt
        {
        padding-top: 20px;
        }

    }
    .text-right
    {
        text-align: right;
    }
    .pt-name
    {
        font-size: 10px;
    }
}
.ft-container::before
{
    left: 0;
    content: "";
    display: block;
    width: 65px;
    height: 7px;
    background: #000000;
    position: absolute;
    top: -4px;
    border-radius: 10px;
}
.pdf-header
{
    padding-top:10px;
    margin-top: 45px;
    .hd-container
    {
        border-bottom: 1px solid #000000;
        position: relative;
        h1{
            color:#000;
            font-size:28px;
            font-weight: 600;
        }
        h5{
            color:#000;
        }
    }
    .pd-con 
    {
    justify-content: center;
    p{
        padding-right: 26px;
        font-size: 10px;   
        margin-bottom: 1px;
    }
    }
    .text-right
    {
        text-align: right;
    }
    .pt-name
    {
        margin-top:10px;
        font-size: 10px;
        p{
            span{
                color:#000;
                font-weight: bold;
            }
        }
    }
    .text-left
    {
        text-align: left;
    }
}
.hd-container:before {
    right: 0;
    content: "";
    display: block;
    width: 65px;
    height: 7px;
    background: #000000;
    position: absolute;
    bottom: -3px;
    border-radius: 10px;
}
.cnt-main{
    min-height: 600px;
    .cnt-header{
        // text-decoration: underline;
        color: #000000;
        padding-top: 10px;
        h2{
            text-decoration: underline;
            font-size: 1.2rem;
        }
        .tbl-contents
        {
            padding-left: 0rem;
            padding-top: 20px;
            font-size: 16px;
            li{
                list-style: none;
                text-decoration: none;
                h4{
                    position: relative;
                    font-size: 12px;
                    
                    span{
                        float: right;
                        font-weight: 600;
                    }
                    &::after{
                        content: '';
                        flex: 1;
                        margin-left: 1rem;
                        height: 0.01rem;
                        background-color: #000;
                        width: 64%;
                        position: absolute;
                        top: 9px;
                    }
                    label{
                        width:26%;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}
.de-info
{
    padding-top: 10px;
    h3{
        font-size: 14px;
        font-weight: 600;
    }
    .de-subdata
    {
        padding-left: 28px;
        h6{
            font-weight: 600;
            font-size: 11px;
            margin-bottom: 0.3rem;
        }
        p{
            font-size: 11px;
            margin-bottom: 0.4rem;
        }
    }
    .pt-tb
    {
        p{
            font-size: 14px;
        }
    }
}
.pdf-inner{
    position: relative;
    .dodle-2{
        position: absolute;
        bottom: 0;
        right: 0;
        float: right;
    }
}
.thnks-cont
{
    .form-main
    {
        padding: 60px;
        border-radius: 20px;
        .thks-data
        {
            width: 600px;
            margin: auto;
        }
        
    }
}
#facilitypreview
{
    .modal-header
    {
        box-shadow: 0px 0px 10px rgba(145, 145, 145, 0.01), 0px 0px 9px rgba(145, 145, 145, 0.04), 0px 0px 8px rgba(145, 145, 145, 0.14), 0px 0px 6px rgba(145, 145, 145, 0.23), 0px 0px 3px rgba(145, 145, 145, 0.27), 0px 0px 0px rgba(145, 145, 145, 0.28);
    .sv-btn
    {
        margin-top: 10px;
        .cst-gry {
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            color: #2D2D2D;
            padding: 6px 20px;
            &:hover{
                background-color: #ECECEC;
                color:#007ACC;
                border-radius: 10px;
            }
        }
    }   
    }
}
.pdf-tbl
{
    width: 100%;
    thead{
        background: #f7f8f9;
        border: 0.01rem solid #a3a3a3;
       th{
        // padding: 5px;
        color:#000
       }
    }
    tbody{
        border: 0.01rem solid #a3a3a3;
        tr{
            td
            {
                p{
                    font-size: 12px!important;
                    font-weight: bold;
                    margin-bottom: 0px;
                    color:#000;
                }
                a{
                    color:#000;
                }
            }
        }
    }
}
.signature-pad
{
    position: relative;
    width: 620px;
    #sig-canvas {
        border: 2px dotted #CCCCCC;
        border-radius: 15px;
        cursor: crosshair;
        width: 100%;
        height: 100%;
      }
      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        pointer-events: none;
        width: 100%;
        height: 100%;
        // background-image: url(../assets/img/e-signature.svg);
        background-size: 180px;
        background-repeat: no-repeat;
        background-position: 30%;
        display: block;
    }
}
.no-before
{
    &:after
    {
        display: none!important;
    }
}
.fc-hd{
    text-align: left;
    font-weight: 600;
    text-decoration: underline;
    color:#000;
}
.tablewrap{
.br-btm{
    border-bottom: 0.01rem solid #a3a3a33e;
}
}
.ips-content
{
    .pdf-tbl
    {
        // width:65%;
        margin: 0 auto;
        margin-bottom: 20px;
    }
}

@media(max-width:600px)
{
    .cmp-data
    {
        .cus-icon {
            font-size: 12px;
        }
        svg{
            vertical-align: baseline;
        }
    }
    
}