
.box-content
{
    display: flex;
    .box {
        width: 40%;
        display: inline-block;
        padding-top: 2px;
        padding-bottom: 2px;
        padding: 10px;
        @media(max-width:1290px)
        {
            width: 50%;
        }
        p{
            margin-bottom: 0px;
            
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            // line-height: 14px;
    
            color: #A3A3A3;
        }
      }
      .box-time
      {
        width:50%;
        padding: 10px;
        padding-left: 20px;
        h2{
            font-size: 40px;
            color: #48B473;
            margin-bottom: 0px;
        }
        p{
            margin-bottom: 0px;
        }
      }
}
  .box-time::after {
    content: "";
    height: 90px;
    width: 0.02px;
    background: black;
    top: 10px;
    bottom: 0;
    margin-left: 10px;
    border: 0.2px solid #D9D9D9;
    position: absolute;
    left: 37%;
    @media(max-width:1290px)
    {
        left: 42%;
    }

}
.prg-bar
{
    padding: 0px;
}
.box::before {
    content: "";
    height: 90px;
    width: 0.02px;
    background: black;
    top: 10px;
    bottom: 0;
    margin-left: 10px;
    border: 0.2px solid #D9D9D9;
    position: absolute;
    left: -23px;

}
  
  .circular-progress
  {
    position: relative;
    height: 65px;
    width: 65px;
    border-radius: 50%;
    // background: conic-gradient(#7d2ae8 3.6deg, #ededed 0deg);
    background: #48B473;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3px;
  }
.circular-progress::before{
    content: "";
    position: absolute;
    height:  50px;
    width:  50px;
    border-radius: 50%;
    background-color: #fff;
}
.progress-value{
    position: relative;
    font-size: 14px;
    font-weight: 600;
    color: #9E9E9E;
}

.com-progressbar
{
    .progress{
      background: #D9D9D9;
    }
}
.track-btn{
    .prg-bar{
        margin-top: 18px;
        .box{
            width: 48%;
            .circular-progress{
                margin-top: 10px;
            }
            p{
                color: #A3A3A3;
                font-size: 11px;
                @media(max-width:1024px)
                {
                    font-size: 9px;
                }
            }
            &::before
            {
                display: none;
            }
        }
        .box-time{
            position: relative;
            padding-left: 30px;
            width: 52%;
            p{
                color: #A3A3A3;
                font-size: 11px;
                @media(max-width:1024px)
                {
                    font-size: 8px;
                }
            }
        
            &:after{
                left:0px;
            }
        }
    }
    .progress{
        margin-top: 5px;
    background: #D9D9D9;
    .bg-violet{
        background: #9747FF;
        border-radius: 20px;
        }

    }
    .pgr{
        color:#9E9E9E;
        font-size: 13px;
        @media(max-width:1024px)
        {
            font-size: 10px;
        }
    }
    .pd-0{
        padding: 0px;
    }
}